.willetBody {
  background-color: rgb(255, 255, 255);
  height: 1000px;
}
.hide {
  display: none;
}
.primaryBackground {
  background-color: #d15428;
}
.primaryText {
  color: #d15428;
}
.mainText {
  /* font-family: "Bourton W00", sans-serif; */
  font-family: "Roboto Condensed", sans-serif;
  color: #fff;
  font-size: 110px;
  text-transform: uppercase;
  font-weight: 900;
  padding-top: 5%;
  line-height: 100%;
}
.contentText {
  font-family: "Roboto Condensed", sans-serif;
  color: #fff;
  font-size: 30px;
  /* padding-bottom:3%; */
  font-weight: 900;
}

.posterText {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 10;
  color: #000;
  font-weight: 590;
}
.whiteButton {
  cursor: pointer;
  padding: 16px 90px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  background-color: transparent;
  border: 3px solid #ffffff;
  color: #fff;
}
.whiteButton:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  text-decoration: none;
}
.primaryButton {
  cursor: pointer;
  padding: 16px 90px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  background-color: transparent;
  border: 3px solid #d15428;
  color: #d15428;
}
.primaryButton.pb {
  padding: 14px 30px;
}
.primaryButton:hover {
  background: rgba(231, 145, 15, 0.1);
  color: #d15428;
  text-decoration: none;
}
.bgWhite {
  background-color: #fff;
}
.textWhite {
  color: #fff;
}
.bgPrimary {
  background-color: #d15428;
}
.textPrimary {
  color: #d15428;
}
.absolute {
  position: absolute;
}
@media only screen and (min-device-width: 1020px) and (max-device-width: 1250px) {
  .mainText {
    font-size: 105px;
    font-weight: 900;
    padding-top: 5%;
    line-height: 90%;
  }
  .posterText {
    font-size: 20px;
    font-weight: 590;
  }
}
@media only screen and (min-device-width: 900px) and (max-device-width: 1020px) {
  .mainText {
    font-size: 92px;
    font-weight: 900;
    padding-top: 5%;
    line-height: 90%;
  }
  .posterText {
    font-size: 18px;
    font-weight: 590;
  }
  .primaryButton {
    padding: 13px 80px;
  }
}
@media only screen and (min-device-width: 600px) and (max-device-width: 900px) {
  .mainText {
    font-size: 60px;
    font-weight: 900;
    padding-top: 5%;
    line-height: 90%;
  }
  .posterText {
    font-size: 16px;
    font-weight: 590;
  }
  .primaryButton {
    padding: 10px 60px;
  }
}
@media only screen and (min-device-width: 500px) and (max-device-width: 600px) {
  .mainText {
    font-size: 55px;
    font-weight: 900;
    padding-top: 5%;
    line-height: 90%;
  }
  .posterText {
    font-size: 11px;
    font-weight: 590;
  }
  .primaryButton {
    padding: 8px 30px;
  }
}
@media only screen and (min-device-width: 420px) and (max-device-width: 500px) {
  .mainText {
    font-size: 85px;
    font-weight: 900;
    padding-top: 5%;
    line-height: 90%;
  }
  .posterText {
    font-size: 18px;
    font-weight: 590;
  }
  .primaryButton {
    padding: 8px 30px;
  }
}
@media only screen and (min-device-width: 330px) and (max-device-width: 420px) {
  .mainText {
    font-size: 65px;
    font-weight: 900;
    padding-top: 5%;
    line-height: 90%;
  }
  .posterText {
    font-size: 16px;
    font-weight: 590;
  }
  .primaryButton {
    padding: 8px 20px;
  }
}
@media only screen and (min-device-width: 100px) and (max-device-width: 330px) {
  .mainText {
    font-size: 45px;
    font-weight: 900;
    padding-top: 5%;
    line-height: 90%;
  }
  .posterText {
    font-size: 14px;
    font-weight: 590;
  }
  .primaryButton {
    padding: 8px 10px;
  }
}
.opacityAnimation1 {
  -webkit-animation: fadeInFromNone 4s ease-in;
  -moz-animation: fadeInFromNone 3s ease-in;
  -o-animation: fadeInFromNone 3s ease-in;
  animation: fadeInFromNone 3s ease-in;
}
.opacityAnimation {
  -webkit-animation: fadeInFromNone 3s ease-out;
  -moz-animation: fadeInFromNone 3s ease-out;
  -o-animation: fadeInFromNone 3s ease-out;
  animation: fadeInFromNone 3s ease-out;
}
@-webkit-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}
