.building {
  width: 435%;
  z-index: 2;
  margin: 0 auto;

  /* display: inline-block; */
}
.building1 {
  z-index: 2;
  margin-bottom: 10%;
}
.buildingSection {
  position: static;
  margin-left: -1.1%;
}
.mainPosition {
  padding-top: 28%;
  padding-left: 53%;
  margin-right: 5px;
  z-index: 3;
}
.buildingPoster {
  background-color: #f4f4ef;
  float: right;
  display: block;
  z-index: 1;
  width: 66%;
  margin-left: 32%;
  padding-top: 5%;
  margin-top: 48%;
  padding-top: 5%;
  padding-left: 30%;
  padding-right: 2%;
  padding-bottom: 4%;
}
.contentButton {
  padding: 16px 90px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  background-color: transparent;
  border: 3px solid #d15428;
  color: #d15428;
}
.contentButton:hover {
  background: rgba(84, 132, 177, 0.1);
}
.buildContent {
  margin-top: 5%;
  margin-bottom: 10%;
}
.buildContent2 {
  background-color: #f4f4ef;
  padding-left: 10%;
  padding-top: 3%;
  padding-right: 28%;
  padding-bottom: 8%;
}
.homeBack1 {
  height: 75%;
}
.buildBackImage {
  background-image: url("./images/soraImage2.jpg");
  background-position: center;
  background-size: cover;
  height: 60%;
  background-position: 50% 50%;

  position: static;
  top: -6.25px;
  left: -6.25px;
  bottom: -6.25px;
  right: -6.25px;
  padding-left: 5%;
  padding-top: 100%;
}
.mainTextBuild {
  font-family: "Roboto Condensed", sans-serif;
  color: #d15428;
  font-size: 80px;
  text-transform: uppercase;
  font-weight: 900;
  padding-bottom: 2%;
  line-height: 100%;
  margin-top: -8%;
}
.contentTextBuilt {
  padding-bottom: 2%;
  font-family: sans-serif;
  color: rgb(20, 18, 18);
  font-size: 18px;
}
.contentText {
  padding-bottom: 1%;
}
.homeText {
  padding-top: 5%;
}
.homeSection {
  height: 90%;
  margin-bottom: -50%;
}
@media only screen and (min-device-width: 1550px) and (max-device-width: 3226px) {
  .homeSection {
    height: 85%;
    margin-bottom: -20%;
  }
  .build {
    display: block;
  }
  .build1 {
    display: none;
  }
  .mainPosition {
    padding-top: 26%;
    padding-left: 60%;
  }
  .buildingPoster {
    padding-top: 7%;
    padding-left: 45%;
    padding-right: 5%;
    margin-top: 46%;
    width: 77%;
    padding-bottom: 10%;
    margin-left: 21%;
  }
  .buildContent {
    margin-top: 5%;
    margin-bottom: 19%;
  }
}
@media only screen and (min-device-width: 1250px) and (max-device-width: 1550px) {
  .homeSection {
    height: 85%;
    margin-bottom: -20%;
  }
  .build {
    display: block;
  }
  .build1 {
    display: none;
  }
  .mainPosition {
    padding-top: 26%;
    padding-left: 60%;
  }
  .buildingPoster {
    padding-top: 7%;
    padding-left: 40%;
    padding-right: 5%;
    margin-top: 42%;
    width: 77%;
    padding-bottom: 5%;
    margin-left: 21%;
  }
  .buildContent {
    margin-top: 5%;
    margin-bottom: 19%;
  }
}
@media only screen and (min-device-width: 1020px) and (max-device-width: 1250px) {
  .build {
    display: block;
  }
  .build1 {
    display: none;
  }
  .mainPosition {
    padding-top: 17%;
  }
  .buildingPoster {
    padding-top: 7%;
    padding-left: 34%;
    padding-right: 5%;
    margin-top: 35%;
    width: 77%;
    padding-bottom: 5%;
    margin-left: 23%;
  }
  .buildContent {
    margin-top: 5%;
    margin-bottom: 19%;
  }
}
@media only screen and (min-device-width: 900px) and (max-device-width: 1020px) {
  .build {
    display: block;
  }
  .build1 {
    display: none;
  }
  .mainPosition {
    padding-top: 15%;
  }
  .buildingPoster {
    margin-top: 36%;
    padding-top: 4%;
    padding-left: 35%;
    padding-right: 2%;
    width: 82%;
    margin-left: 19%;
    padding-bottom: 5%;
  }
  .buildContent {
    margin-top: 1%;
    margin-bottom: 12%;
    padding-right: 10%;
  }
}

@media only screen and (min-device-width: 700px) and (max-device-width: 900px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 80%;
    margin-bottom: 5%;
  }
}
@media only screen and (min-device-width: 500px) and (max-device-width: 700px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 70%;
    margin-bottom: 3%;
  }
  .mainTextBuild {
    font-size: 65px;
    line-height: 100%;
  }
  .contentTextBuilt {
    font-size: 22px;
  }
  .contentButton {
    padding: 12px 60px;
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 500px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 60%;
    margin-bottom: 7%;
  }
  .mainTextBuild {
    font-size: 45px;
    line-height: 100%;
  }
  .contentTextBuilt {
    font-size: 18px;
  }
  .contentButton {
    padding: 8px 40px;
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 200px) and (max-device-width: 300px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 55%;
    margin-bottom: 3%;
  }
  .mainTextBuild {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextBuilt {
    font-size: 16px;
  }
  .contentButton {
    padding: 5px 20px;
    font-size: 11px;
  }
}
@media only screen and (min-device-width: 100px) and (max-device-width: 200px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 25%;
    margin-bottom: 3%;
  }
  .mainTextBuild {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextBuilt {
    font-size: 16px;
  }
  .contentButton {
    padding: 5px 20px;
    font-size: 11px;
  }
}

@media only screen and (min-width: 1250px) and (max-width: 3226px) {
  .homeSection {
    height: 85%;
    margin-bottom: -20%;
  }
  .build {
    display: block;
  }
  .build1 {
    display: none;
  }
  .buildingSection {
    margin-bottom: 15%;
  }
}
@media only screen and (min-width: 1020px) and (max-width: 1250px) {
  .build {
    display: block;
  }
  .build1 {
    display: none;
  }
  .mainPosition {
    padding-top: 17%;
  }
  .buildingPoster {
    padding-top: 7%;
    padding-left: 34%;
    padding-right: 5%;
    margin-top: 35%;
    width: 77%;
    padding-bottom: 5%;
    margin-left: 23%;
  }
  .buildContent {
    margin-top: 5%;
    margin-bottom: 19%;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1020px) {
  .build {
    display: block;
  }
  .build1 {
    display: none;
  }
  .mainPosition {
    padding-top: 15%;
  }
  .buildingPoster {
    margin-top: 36%;
    padding-top: 4%;
    padding-left: 35%;
    padding-right: 2%;
    width: 82%;
    margin-left: 19%;
    padding-bottom: 5%;
  }
  .buildContent {
    margin-top: 1%;
    margin-bottom: 12%;
    padding-right: 10%;
  }
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 80%;
    margin-bottom: 5%;
  }
}
@media only screen and (min-width: 500px) and (max-width: 700px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 70%;
    margin-bottom: 3%;
  }
  .mainTextBuild {
    font-size: 65px;
    line-height: 100%;
  }
  .contentTextBuilt {
    font-size: 22px;
  }
  .contentButton {
    padding: 12px 60px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 500px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 60%;
    margin-bottom: 7%;
  }
  .mainTextBuild {
    font-size: 45px;
    line-height: 100%;
  }
  .contentTextBuilt {
    font-size: 18px;
  }
  .contentButton {
    padding: 8px 40px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 300px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 55%;
    margin-bottom: 3%;
  }
  .mainTextBuild {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextBuilt {
    font-size: 16px;
  }
  .contentButton {
    padding: 5px 20px;
    font-size: 11px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 200px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 25%;
    margin-bottom: 3%;
  }
  .mainTextBuild {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextBuilt {
    font-size: 16px;
  }
  .contentButton {
    padding: 5px 20px;
    font-size: 11px;
  }
}
