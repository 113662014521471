.homeBack {
  background-image: url("./images/sora.jpg");
  background-position: 0px -200px;
  background-size: cover;
  background-repeat: no-repeat;
  height: 60%;
  padding-left: 5%;
  padding-right: 20%;
  padding-top: 3%;
}
.homeContent {
  padding-left: 3%;
  padding-top: 3%;
  padding-right: 3%;
  padding-bottom: 3%;
}
.homeBack1 {
  height: 75%;
}
.backImage {
  background-image: url("./images/sora.jpg");
  background-position: center;
  background-size: cover;
  height: 60%;
  background-position: 50% 50%;

  position: static;
  top: -6.25px;
  left: -6.25px;
  bottom: -6.25px;
  right: -6.25px;
  padding-left: 5%;
  padding-top: 40%;
}

.mainTextHome {
  font-family: "Roboto Condensed", sans-serif;
  color: #fff;
  font-size: 80px;
  text-transform: uppercase;
  font-weight: 900;
  padding-bottom: 2%;
  line-height: 100%;
}
.contentTextHome {
  padding-bottom: 2%;
  font-family: "Roboto Condensed", sans-serif;
  color: #fff;
  font-size: 28px;

  font-weight: 900;
}
.contentText {
  padding-bottom: 1%;
}
.homeText {
  padding-top: 5%;
}
.homeSection {
  height: 90%;
  margin-bottom: -50%;
}
@media only screen and (min-device-width: 1526px) and (max-device-width: 3226px) {
  .homeSection {
    height: 140%;
    margin-bottom: -20%;
  }
  .homeBack {
    display: block;
  }
  .homeBack1 {
    display: none;
  }
}
@media only screen and (min-device-width: 1226px) and (max-device-width: 1526px) {
  .homeSection {
    height: 140%;
    margin-bottom: -30%;
  }
  .homeBack {
    display: block;
  }
  .homeBack1 {
    display: none;
  }
}
@media only screen and (min-device-width: 1130px) and (max-device-width: 1226px) {
  .homeSection {
    height: 85%;
    margin-bottom: -20%;
  }
  .homeBack {
    display: block;
  }
  .homeBack1 {
    display: none;
  }
}
@media only screen and (min-device-width: 900px) and (max-device-width: 1130px) {
  .homeBack {
    display: block;
  }
  .homeBack1 {
    display: none;
  }
  .homeSection {
    height: 70%;
    margin-bottom: -5%;
  }
  .contentText {
    padding-top: 3%;
    padding-bottom: 3%;
  }
  .homeText {
    padding-top: 5%;
  }
  .homeSection {
    height: 90%;
    margin-bottom: -30%;
  }
  .homeBack {
    height: 58%;
  }
}

@media only screen and (min-device-width: 700px) and (max-device-width: 900px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 80%;
    margin-bottom: 5%;
  }
}
@media only screen and (min-device-width: 500px) and (max-device-width: 700px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 70%;
    margin-bottom: 3%;
  }
  .mainTextHome {
    font-size: 65px;
    line-height: 100%;
  }
  .contentTextHome {
    font-size: 22px;
  }
  .whiteButton {
    padding: 12px 60px;
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 500px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 60%;
    margin-bottom: 7%;
  }
  .mainTextHome {
    font-size: 45px;
    line-height: 100%;
  }
  .contentTextHome {
    font-size: 18px;
  }
  .whiteButton {
    padding: 8px 40px;
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 200px) and (max-device-width: 300px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 55%;
    margin-bottom: 3%;
  }
  .mainTextHome {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextHome {
    font-size: 16px;
  }
  .whiteButton {
    padding: 5px 20px;
    font-size: 11px;
  }
}
@media only screen and (min-device-width: 100px) and (max-device-width: 200px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 25%;
    margin-bottom: 3%;
  }
  .mainTextHome {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextHome {
    font-size: 16px;
  }
  .whiteButton {
    padding: 5px 20px;
    font-size: 11px;
  }
}
@media only screen and (min-width: 1526px) and (max-width: 3226px) {
  .homeSection {
    height: 140%;
    margin-bottom: -20%;
  }
  .homeBack {
    display: block;
  }
  .homeBack1 {
    display: none;
  }
}
@media only screen and (min-width: 1226px) and (max-width: 1526px) {
  .homeSection {
    height: 90%;
    margin-bottom: -20%;
  }
  .homeBack {
    display: block;
  }
  .homeBack1 {
    display: none;
  }
}
@media only screen and (min-width: 1130px) and (max-width: 1226px) {
  .homeSection {
    height: 85%;
    margin-bottom: -20%;
  }
  .homeBack {
    display: block;
  }
  .homeBack1 {
    display: none;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1130px) {
  .homeBack {
    display: block;
  }
  .homeBack1 {
    display: none;
  }
  .homeSection {
    height: 70%;
    margin-bottom: -5%;
  }
  .contentText {
    padding-top: 3%;
    padding-bottom: 3%;
  }
  .homeText {
    padding-top: 5%;
  }
  .homeSection {
    height: 90%;
    margin-bottom: -30%;
  }
  .homeBack {
    height: 58%;
  }
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 80%;
    margin-bottom: 5%;
  }
}
@media only screen and (min-width: 500px) and (max-width: 700px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 70%;
    margin-bottom: 3%;
  }
  .mainTextHome {
    font-size: 65px;
    line-height: 100%;
  }
  .contentTextHome {
    font-size: 22px;
  }
  .whiteButton {
    padding: 12px 60px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 500px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 60%;
    margin-bottom: 7%;
  }
  .mainTextHome {
    font-size: 45px;
    line-height: 100%;
  }
  .contentTextHome {
    font-size: 18px;
  }
  .whiteButton {
    padding: 8px 40px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 300px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 55%;
    margin-bottom: 3%;
  }
  .mainTextHome {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextHome {
    font-size: 16px;
  }
  .whiteButton {
    padding: 5px 20px;
    font-size: 11px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 200px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 25%;
    margin-bottom: 3%;
  }
  .mainTextHome {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextHome {
    font-size: 16px;
  }
  .whiteButton {
    padding: 5px 20px;
    font-size: 11px;
  }
}
