.menu {
  background-color: #222967;
}
.hide {
  display: none;
}
.bgPrimary {
  background-color: #222967;
}
.menuSection {
  height: 100%;
  background-color: #d6d6d5;
}

.menuBack {
  background-image: url("./images/bird2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  padding-left: 3%;
  padding-top: 8%;
  padding-bottom: 15%;
  width: 72.7%;
}
.menuBackImage1 {
  background-color: black;
  background-image: url("./images/bird2.png");
  height: 640px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 9%;

  padding-bottom: 10%;
}
.menuText {
  /* font-family: "Bourton W00", sans-serif; */
  font-family: "Roboto Condensed", sans-serif;
  color: #fff;
  font-size: 75px;
  text-transform: uppercase;
  font-weight: 900;
  padding-top: 5%;
  line-height: 80%;
  text-decoration: none !important;
}
.menuText:hover {
  color: #f1c00c;
}
.menuDetails {
  width: 26.3%;
  padding-left: 5%;
  padding-top: 14%;
  background-color: #d6d6d5;
}
.menuSection2 {
  background-color: #d6d6d5;
}
.menuDetail {
  padding-bottom: 14%;
  padding-right: 1%;
  padding-left: 1%;
}
.menuDetails2 {
  background-color: #d6d6d5;
  padding-top: 5%;
}
.menuDetails3 {
  padding-bottom: 15%;
}
.menuDetailHeaderText {
  font-family: "Roboto Condensed", sans-serif;
  color: #d15428;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 900;
  padding-bottom: 10px;
}
.menuDetailText {
  color: #d15428;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.round {
  align-items: center;
  color: #fff;
  background-color: #d15428;
  width: 37px;
  padding: 12px;
  border-radius: 20px;
}
@media only screen and (min-width: 1470px) and (max-width: 5160px) {
  .menuSection {
    display: block;
  }
  .menuSection2 {
    display: none;
  }
}
@media only screen and (min-device-width: 850px) and (max-device-width: 1470px) {
  /* .menuBack{
        width:80%;
    }
    .menuDetails{
        width:20%;
    } */
  .menuSection {
    display: block;
  }
  .menuSection2 {
    display: none;
  }
}
@media only screen and (min-device-width: 650px) and (max-device-width: 850px) {
  .menuSection {
    display: none;
  }
  .menuSection2 {
    display: block;
  }
}
@media only screen and (min-device-width: 570px) and (max-device-width: 650px) {
  .menuSection {
    display: none;
  }
  .menuSection2 {
    display: block;
  }
  .menuText {
    font-size: 60px;
  }
  .menuBackImage1 {
    height: 550px;
  }
}
@media only screen and (min-device-width: 510px) and (max-device-width: 570px) {
  .menuSection {
    display: none;
  }
  .menuSection2 {
    display: block;
  }
  .menuText {
    font-size: 40px;
  }
  .menuBackImage1 {
    height: 500px;
  }
  .menuSub {
    min-width: 500px;
  }
  .alignMenu2 {
    padding-top: 10%;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 510px) {
  .menuSection {
    display: none;
  }
  .menuSection2 {
    display: block;
  }
  .menuText {
    font-size: 30px;
  }
  .menuBackImage1 {
    height: 400px;
  }
  .menuSub {
    min-width: 300px;
  }
  .alignMenu2 {
    padding-top: 10%;
  }
  .menuDetailHeaderText {
    font-size: 23px;
    padding-bottom: 3px;
  }
  .menuDetailText {
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 310px) and (max-device-width: 372px) {
  .menuSection {
    display: none;
  }
  .menuSection2 {
    display: block;
  }
  .menuText {
    font-size: 30px;
  }
  .menuBackImage1 {
    height: 350px;
  }
  .menuSub {
    min-width: 300px;
  }
  .alignMenu2 {
    padding-top: 15%;
  }
  .menuDetailHeaderText {
    font-size: 20px;
    padding-bottom: 3px;
  }
  .menuDetailText {
    font-size: 10px;
  }
}
