.neighborhoods {
  z-index: 2;
  /* background-image: url("./images/soraImage.jpg"); */
  background: url("./images/soraImage1.jpg"),
    linear-gradient(rgb(0, 0, 0, 1), rgba(0, 0, 0, 1));
  /* background-position: top; */
  background-size: cover;
  height: 100%;
  margin-right: 22%;
  width: 97%;
  box-sizing: inherit;
  box-shadow: 50px 50px #f4f4ef;
}
.contentPro {
  padding-left: 20%;
  padding-right: 20%;
}
.neighborhood2 {
  z-index: 2;
}

.neighborhoodSection
{
  position: static;
  margin-left: -1.1%;
  margin-top: 20%;
  margin-bottom: 20%;
  
}
.textNeighborhood {
  margin-top: -8%;
  z-index: 3;
  font-size: 90px;
  text-transform: uppercase;
  font-weight: 800;
  font-family: "Arimo", sans-serif;
  color:#293158;
}
.contentTextNeighborhood {
  padding-top: 15px;
  padding-bottom: 25px;
  font-size: 20px;
  color: #4c5581;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
}
.neighborhoodsPoster {
  background-color: #f4f4ef;
  float: right;
  display: block;
  z-index: 1;
  width: 56%;
}
.contentButton {
  padding: 16px 90px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  background-color: transparent;
  border: 3px solid #3e4670;
  color: #3e4670;
}
.contentButton:hover {
 text-decoration: none;
  color: #3e4670;
}

.homeBack1 {
  height: 75%;
}

.contentText {
  padding-bottom: 1%;
}
.homeText {
  padding-top: 5%;
}

@media only screen and (min-width: 1926px) and (max-width: 3226px) {
  .neighborhoodsPoster {
    padding-top: 28%;
    padding-left: 39%;
    padding-right: 5%;
    margin-top: 17%;

    width: 77%;
    padding-bottom: 10%;
    margin-left: 21%;
  }

  .neighborhoods {
    height: 100%;
    height: 700px;
  }
  .neighborhoodSection1
{
  margin-bottom: 10%;
  }
  .textNeighborhood {
    margin-top: -5%;
    z-index: 3;
    font-size: 180px;
    text-transform: uppercase;
    font-weight: 800;
  }
  .contentTextNeighborhood {
    font-size: 50px;
  }
}
@media only screen and (min-width: 1700px) and (max-width: 1926px) {
  .neighborhoodsPoster {
    padding-top: 28%;
    padding-left: 39%;
    padding-right: 5%;
    margin-top: 17%;

    width: 77%;
    padding-bottom: 10%;
    margin-left: 21%;
  }

  .neighborhoods {
    height: 100%;
    padding-right: 10%;
    height: 700px;
    padding-left: 10%;
  }
  .neighborhoodSection1
{
  margin-bottom: 10%;
  }
  .textNeighborhood {
    margin-top: -6%;
    z-index: 3;
    font-size: 110px;
    text-transform: uppercase;
    font-weight: 800;
  }
  .contentTextNeighborhood {
    font-size: 20px;
  }
}@media only screen and (min-width: 1520px) and (max-width: 1700px) {
  .neighborhoodsPoster {
    padding-top: 28%;
    padding-left: 39%;
    padding-right: 5%;
    margin-top: 17%;
    width: 77%;
    padding-bottom: 10%;
    margin-left: 21%;
  }

  .neighborhoods {
    height: 100%;
    padding-right: 10%;
    padding-left: 10%;
    height: 700px;
  }
  .neighborhoodSection1
{
  margin-bottom: 10%;
  }
  .textNeighborhood {
    margin-top: -6%;
    z-index: 3;
    font-size: 110px;
    text-transform: uppercase;
    font-weight: 800;
  }
  .contentTextNeighborhood {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1380px) and (max-width: 1520px) {
  .neighborhoodsPoster {
    padding-top: 28%;
    padding-left: 39%;
    padding-right: 5%;
    margin-top: 17%;
    width: 77%;
    padding-bottom: 10%;
    margin-left: 21%;
  }

  .neighborhoods {
    height: 600px;
    padding-right: 10%;
    padding-left: 10%;
  }
  .neighborhoodSection1
{
  margin-bottom: 10%;
  }
  .textNeighborhood {
    margin-top: -6%;
    z-index: 3;
    font-size: 110px;
    text-transform: uppercase;
    font-weight: 800;
  }
  .contentTextNeighborhood {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1250px) and (max-width: 1380px) {
  .neighborhoodsPoster {
    padding-top: 17%;
    padding-left: 39%;
    padding-right: 5%;
    margin-top: 20%;
    width: 77%;
    padding-bottom: 10%;
    margin-left: 20%;
  }
  .neighborhoods {
    height: 95%;
    height: 500px;
  }
  .textNeighborhood {
    font-size: 115px;
    margin-top: -5%;
  }
  .neighborhoodSection
{

  margin-bottom: 10%;
    margin-top: 25%;
  }
}

@media only screen and (min-width: 1150px) and (max-width: 1250px) {
  .neighborhoodsPoster {
    padding-top: 19%;
    padding-left: 34%;
    padding-right: 5%;
    margin-top: 21%;
    width: 77%;
    padding-bottom: 6%;
    margin-left: 21%;
  }
  .neighborhoods {
    height: 90%;
    height: 500px;
  }
  .textNeighborhood {
    font-size: 115px;
    margin-top: -5%;
  }
  .neighborhoodSection1
{
    margin-bottom: 45%;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1150px) {
  .neighborhoodsPoster {
    margin-top: 24%;
    padding-top: 19%;
    padding-left: 35%;
    padding-right: 2%;
    width: 80%;
    margin-left: 18%;
    padding-bottom: 5%;
  }
  .neighborhoods {
    margin-top: 6%;
    height: 500px;

  }
  .textNeighborhood {
    font-size: 110px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
  margin-bottom: 10%;
  }
  .neighborhoodSection{
    margin-bottom: 10%;
  }
}
@media only screen and (min-width: 890px) and (max-width: 1000px) {
  .neighborhoodsPoster {
    margin-top: 33%;
    padding-top: 20%;
    padding-left: 35%;
    padding-right: 2%;
    width: 80%;
    margin-left: 19%;
    padding-bottom: 5%;
  }
  .neighborhoods {
    margin-top: 15%;
    height: 70%;
    height: 500px;
  }

  .neighborhoods {
    margin-top: 6%;
    height: 75%;
    height: 500px;
  }
  .textNeighborhood {
    font-size: 95px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
    margin-bottom: 52%;
    margin-top: 32%;
  }
}
@media only screen and (min-width: 820px) and (max-width: 890px) {
  .neighborhoodsPoster {
    margin-top: 20%;
    padding-top: 18%;
    padding-left: 35%;
    padding-right: 2%;
    width: 80%;
    margin-left: 17%;
    padding-bottom: 5%;
  }

  .neighborhoods {
    margin-top: 6%;
    height: 75%;
    height: 450px;
  }
  .textNeighborhood {
    font-size: 85px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
    margin-bottom: 57%;
    margin-top: 10%;
  }
}
@media only screen and (min-width: 780px) and (max-width: 820px) {
  .neighborhoodsPoster {
    margin-top: 17%;
    padding-top: 20%;
    padding-left: 35%;
    padding-right: 2%;
    width: 80%;
    margin-left: 17%;
    padding-bottom: 5%;
  }
  .neighborhoods {
    height: 58%;
    font-weight: 1200;
    height: 450px;
  }
  .neighborhoods {
    margin-top: 6%;
    height: 75%;
    height: 450px;
  }
  .textNeighborhood {
    font-size: 80px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
    margin-bottom: 60%;
    margin-top: 10%;
  }
}
@media only screen and (min-width: 700px) and (max-width: 780px) {
  .neighborhoodsPoster {
    margin-top: 15%;
    padding-top: 19%;
    padding-left: 35%;
    padding-right: 2%;
    width: 80%;
    margin-left: 17%;
    padding-bottom: 5%;
  }

  .contentTextNeighborhood {
    font-size: 18px;
  }
  .neighborhoods {
    margin-top: 6%;
    height: 75%;
    height: 400px;
  }
  .textNeighborhood {
    font-size: 75px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
    margin-bottom: 64%;
    margin-top: 10%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 700px) {
  .contentButton {
    padding: 12px 60px;
    font-size: 12px;
  }
  .neighborhoodsPoster {
    margin-top: 28%;
    padding-top: 30%;
    padding-left: 35%;
    padding-right: 2%;
    width: 80%;
    margin-left: 17%;
    padding-bottom: 5%;
  }

  .contentTextNeighborhood {
    font-size: 20px;
  }
  .neighborhoods {
    margin-top: 6%;
    height: 75%;
    height: 400px;
  }
  .textNeighborhood {
    font-size: 70px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
    margin-bottom: 78%;
    margin-top: 10%;
  }
}
@media only screen and (min-width: 500px) and (max-width: 600px) {
  .contentButton {
    padding: 12px 60px;
    font-size: 12px;
  }
  .neighborhoodsPoster {
    margin-top: 30%;
    padding-top: 43%;
    padding-left: 35%;
    padding-right: 2%;
    width: 80%;
    margin-left: 17%;
    padding-bottom: 5%;
  }

  .contentTextNeighborhood {
    font-size: 17px;
  }
  .neighborhoods {
    margin-top: 6%;
    height: 75%;
    height: 400px;
  }
  .textNeighborhood {
    font-size: 55px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
    margin-bottom: 90%;
    margin-top: 10%;
  }
}
@media only screen and (min-width: 400px) and (max-width: 500px) {
  .contentButton {
    padding: 8px 40px;
    font-size: 12px;
  }
  .neighborhoodsPoster {
    margin-top: 30%;
    padding-top: 45%;
    padding-left: 35%;
    padding-right: 2%;
    width: 80%;
    margin-left: 16%;
    padding-bottom: 5%;
  }

  .neighborhoods {
    margin-top: 6%;
    height: 60%;
    height: 300px;
  }
  .textNeighborhood {
    font-size: 40px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
    margin-bottom: 95%;
    margin-top: 10%;
  }
  .contentTextNeighborhood {
    font-size: 15px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 400px) {
  .contentButton {
    padding: 8px 40px;
    font-size: 12px;
  }
  .neighborhoodsPoster {
    margin-top: 36%;
    padding-top: 57%;
    padding-left: 35%;
    padding-right: 2%;
    width: 80%;
    margin-left: 16%;
    padding-bottom: 5%;
  }
  .neighborhoods {
    margin-top: 6%;
    height: 50%;
    height: 300px;
  }
  .textNeighborhood {
    font-size: 33px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
    margin-bottom: 110%;
    margin-top: 10%;
  }
  .contentTextNeighborhood {
    font-size: 12px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 300px) {
  .contentButton {
    padding: 5px 20px;
    font-size: 11px;
  }

  .contentTextNeighborhood {
    font-size: 16px;
  }
  .neighborhoods {
    margin-top: 6%;
    height: 40%;
    height: 200px;
  }
  .textNeighborhood {
    font-size: 20px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
    margin-bottom: 12%;
    margin-top: 10%;
  }
  .contentTextNeighborhood {
    font-size: 8px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 200px) {
  .contentButton {
    padding: 5px 20px;
    font-size: 11px;
  }
}
