@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");
.InterestedSection {
  padding: 5%;
  margin-top: 5%;
  align-items: center;
}
.Box {
  border: 10px solid #d15428;
  padding-top: 7%;
  padding-bottom: 7%;
}
.interestHeading {
  font-family: "Roboto Condensed", sans-serif;
  color: #d15428;
  font-size: 105px;
  text-transform: uppercase;
  font-weight: 1000;
  padding-bottom: 2%;
  line-height: 100%;
  
}
.interestContent {
  font-family: sans-serif;
  color: #000;
  font-size: 25px;
  line-height: 100%;
  padding-bottom: 2%;
  font-family: "Montserrat", sans-serif;
  
}
@media only screen and (min-device-width: 2020px) and (max-device-width: 3250px) {
  .interestHeading {
    font-size: 175px;
  }
  .interestContent {
    font-size: 55px;
  }
}
@media only screen and (min-device-width: 1000px) and (max-device-width: 2020px) {
  .interestHeading {
    font-size: 110px;
  }
  .interestContent {
    font-size: 45px;
  }
}
@media only screen and (min-device-width: 800px) and (max-device-width: 1000px) {
  .interestHeading {
    font-size: 80px;
  }
  .interestContent {
    font-size: 35px;
  }
}
@media only screen and (min-device-width: 600px) and (max-device-width: 800px) {
  .interestHeading {
    font-size: 70px;
  }
  .interestContent {
    font-size: 30px;
  }
  .Box {
    padding-bottom: 7%;
  }
}
@media only screen and (min-device-width: 400px) and (max-device-width: 600px) {
  .interestHeading {
    font-size: 50px;
  }
  .interestContent {
    font-size: 25px;
  }
  .Box {
    padding-bottom: 7%;
  }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 400px) {
  .interestHeading {
    font-size: 40px;
  }
  .interestContent {
    font-size: 20px;
  }
  .Box {
    padding-bottom: 7%;
  }
}
@media only screen and (min-device-width: 200px) and (max-device-width: 300px) {
  .interestHeading {
    font-size: 30px;
  }
  .interestContent {
    font-size: 15px;
  }
  .Box {
    padding-bottom: 7%;
  }
}
