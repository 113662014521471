.logo {
  height: 120px;
  width: 120px;
  position: absolute;
}
.logo1 {
  height: 90px;
  width: 80px;
  position: absolute;
}
.logoPart {
  padding-left: 3.5%;
  cursor: pointer;
}
.menuButton {
  border: none;
  color: white;
  padding: 15px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 17px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  background-color: #d15428;
}

.menuButton:hover {
  background-color: #e47b55;
}
.bgPrimary {
  background-color: #d15428;
}
.textPrimary {
  color: #d15428;
}
.menuPlus {
  -webkit-text-stroke: 2px #d15428;
}
.availableButton {
  background: none !important;
  font-weight: 800;
  border: none;
  font-size: 11px;
  bottom: 2px;
  font-family: arial, sans-serif;
  text-transform: uppercase;
  cursor: pointer;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  padding-right: 7%;
}
.availableButton:hover {
  text-decoration: underline;
  color:#e47b55;
  text-underline-offset: 4px;
}
.navRight {
  padding-top: 1.5rem;
  padding-right: 4%;
  position: fixed;
  width: 100%;
  float: right;
}
.navLeft {
  position: fixed;
  width: 100%;

  float: left;
}
.header {
  position: absolute;
  width: 100%;
  z-index: 10;
}
.turnClose {
  -webkit-animation: turn 0.3s linear 1;
  transform: rotate(45deg);
}
@keyframes turn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
.turnMenu {
  -webkit-animation: turn1 0.3s linear 1 reverse;
  transform: rotate(0deg);
}

@keyframes turn1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
.menuClose {
  animation: changeover 3s linear;
}
@keyframes changeover {
  0% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(10px);
  }
}
.transformAvailability {
  transform: translate(24px);
}

@media only screen and (min-device-width: 200px) and (max-device-width: 900px) {
  .logo {
    height: 65px;
    width: 50px;
  }
  .logo1 {
    height: 65px;
    width: 50px;
  }
  
}
@media only screen and (min-device-width: 450px) and (max-device-width: 550px) {
  .menuButton {
    font-size: 17px;
    padding: 14px 22px;
  
  }
}
@media only screen and (min-device-width: 350px) and (max-device-width: 450px) {
  .menuButton {
    font-size: 16px;
    padding: 11px 19px;
  
  }
}
@media only screen and (min-device-width: 100px) and (max-device-width: 350px) {
  .menuButton {
    font-size: 14px;
    padding: 8px 15px;
  
  }
}