.FooterSection {
  background-color: #f3f3e7;
  padding-top: 3%;
}
.footerLogo {
  height: 100px;
  width: 100px;
  
}

.logoTitle {
  font-size: 60px;
  text-transform: uppercase;
  font-weight: 800;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  font-family: "Montserrat", sans-serif;
  
  color: #d15428;
}
.logoSubTitle {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 10;
  line-height: 5px;
  font-family: sans-serif;
  color: #d15428;
  font-family: "Montserrat", sans-serif;
}
.footerMenu {
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
   font-family: "Montserrat", sans-serif;
  color: #d15428;
  white-space: nowrap;
  cursor: pointer;
}
.footerMenu:hover {
  color: #f1c00c;
}
.menuHead {
  padding-right: 1%;
  padding-left: 1%;
  padding-top: 4%;
}
.footerDetail {
  padding-top: 3%;
  padding-right: 2%;
  padding-left: 2%;
}
.footerDetailHead {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  font-family: "Montserrat", sans-serif;
  color: #d15428;
  white-space: nowrap;
}
.footerDetailContent {
  font-size: 18px;
  font-weight: 350;
  font-family: sans-serif;
  font-family: "Montserrat", sans-serif;
  color: #d15428;
}
.alignDetail {
  padding-left: 20%;
  padding-right: 20%;
}
.footerDetailOfficial {
  padding-top: 2%;
  padding-right: 10%;
  padding-left: 10%;
}
.footerDetailContent2 {
  font-size: 14px;
  line-height: 17px;
  font-weight: 2;
  font-family: "Arimo", sans-serif;
  font-family: "Montserrat", sans-serif;
  color: #d15428;
}
.iconFooter {
  color: #d15428;
  font-size: 20px;
  padding: 0.5%;
}
.alignKamaik {
  text-align: left;
}

@media only screen and (min-width: 1010px) and (max-width: 1160px) {
  .footerDetailOfficial {
    padding-right: 5%;
    padding-left: 5%;
  }
}
@media only screen and (min-device-width: 900px) and (max-width: 1010px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
}
@media only screen and (min-width: 750px) and (max-width: 900px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 20px;
  }
  .footerDetailContent {
    font-size: 16px;
  }
  .footerDetailHead {
    font-size: 15px;
  }
  .iconFooter {
    font-size: 18px;
    padding: 0.3%;
  }
}
@media only screen and (min-width: 650px) and (max-width: 750px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 17px;
  }
  .footerDetailContent {
    font-size: 14px;
  }
  .footerDetailHead {
    font-size: 12px;
  }
  .iconFooter {
    font-size: 15px;
    padding: 0%;
  }
}
@media only screen and (min-width: 500px) and (max-width: 650px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 32px;
  }
  .menuHead {
    padding-bottom: 2%;
  }
  .footerDetailContent {
    font-size: 16px;
  }
  .footerDetailHead {
    font-size: 16px;
  }
  .iconFooter {
    font-size: 15px;
    padding-bottom: 4%;
  }
  .menuSubHead {
    min-width: 400px;
  }
  .footerSubDetail {
    min-width: 400px;
  }
  .footerSubDetailOfficial {
    min-width: 400px;
    padding-top: 2%;
  }
  .alignKamaik {
    text-align: center;
  }
  .footerPara {
    padding-right: 15%;
    padding-left: 15%;
  }
}
@media only screen and (min-width: 350px) and (max-width: 500px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 28px;
  }
  .menuHead {
    padding-bottom: 2%;
  }
  .footerDetailContent {
    font-size: 14px;
  }
  .footerDetailHead {
    font-size: 14px;
  }
  .iconFooter {
    font-size: 15px;
    padding-bottom: 4%;
  }
  .menuSubHead {
    min-width: 400px;
  }
  .footerSubDetail {
    min-width: 400px;
  }
  .footerSubDetailOfficial {
    min-width: 400px;
    padding-top: 2%;
  }
  .alignKamaik {
    text-align: center;
  }
  .footerPara {
    padding-right: 15%;
    padding-left: 15%;
  }
}
@media only screen and (min-width: 100px) and (max-width: 350px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 22px;
  }
  .menuHead {
    padding-bottom: 2%;
  }
  .footerDetailContent {
    font-size: 11px;
  }
  .footerDetailHead {
    font-size: 12px;
  }
  .iconFooter {
    font-size: 12px;
    padding-bottom: 4%;
  }
  .menuSubHead {
    min-width: 200px;
  }
  .footerSubDetail {
    min-width: 200px;
  }
  .footerSubDetailOfficial {
    min-width: 200px;
    padding-top: 2%;
  }
  .alignKamaik {
    text-align: center;
  }
  .footerPara {
    padding-right: 15%;
    padding-left: 15%;
  }
}
@media only screen and (min-device-width: 1010px) and (max-device-width: 1160px) {
  .footerDetailOfficial {
    padding-right: 5%;
    padding-left: 5%;
  }
}
@media only screen and (min-device-width: 900px) and (max-device-width: 1010px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
}
@media only screen and (min-device-width: 750px) and (max-device-width: 900px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 20px;
  }
  .footerDetailContent {
    font-size: 16px;
  }
  .footerDetailHead {
    font-size: 15px;
  }
  .iconFooter {
    font-size: 18px;
    padding: 0.3%;
  }
}
@media only screen and (min-device-width: 650px) and (max-device-width: 750px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 17px;
  }
  .footerDetailContent {
    font-size: 14px;
  }
  .footerDetailHead {
    font-size: 12px;
  }
  .iconFooter {
    font-size: 15px;
    padding: 0%;
  }
}
@media only screen and (min-device-width: 500px) and (max-device-width: 650px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 32px;
  }
  .menuHead {
    padding-bottom: 2%;
  }
  .footerDetailContent {
    font-size: 16px;
  }
  .footerDetailHead {
    font-size: 16px;
  }
  .iconFooter {
    font-size: 15px;
    padding-bottom: 4%;
  }
  .menuSubHead {
    min-width: 400px;
  }
  .footerSubDetail {
    min-width: 400px;
  }
  .footerSubDetailOfficial {
    min-width: 400px;
    padding-top: 2%;
  }
  .alignKamaik {
    text-align: center;
  }
  .footerPara {
    padding-right: 15%;
    padding-left: 15%;
  }
}
@media only screen and (min-device-width: 350px) and (max-device-width: 500px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 28px;
  }
  .menuHead {
    padding-bottom: 2%;
  }
  .footerDetailContent {
    font-size: 14px;
  }
  .footerDetailHead {
    font-size: 14px;
  }
  .iconFooter {
    font-size: 15px;
    padding-bottom: 4%;
  }
  .menuSubHead {
    min-width: 400px;
  }
  .footerSubDetail {
    min-width: 400px;
  }
  .footerSubDetailOfficial {
    min-width: 400px;
    padding-top: 2%;
  }
  .alignKamaik {
    text-align: center;
  }
  .footerPara {
    padding-right: 15%;
    padding-left: 15%;
  }
}
@media only screen and (min-device-width: 200px) and (max-device-width: 350px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 22px;
  }
  .menuHead {
    padding-bottom: 2%;
  }
  .footerDetailContent {
    font-size: 11px;
  }
  .footerDetailHead {
    font-size: 12px;
  }
  .iconFooter {
    font-size: 12px;
    padding-bottom: 4%;
  }
  .menuSubHead {
    min-width: 200px;
  }
  .footerSubDetail {
    min-width: 200px;
  }
  .footerSubDetailOfficial {
    min-width: 200px;
    padding-top: 2%;
  }
  .alignKamaik {
    text-align: center;
  }
  .footerPara {
    padding-right: 15%;
    padding-left: 15%;
  }
}
