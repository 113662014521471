@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  top: 200%;
  left: 50%;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.fontStyle{
  font-family: "Montserrat", sans-serif;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #f76c39;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.bgPrimary{
  background-color: #da6940;
  color: #fff;
}
.textAvailableUnit {
  margin-left: 4%;
  font-size: 75px;
  text-transform: uppercase;
  font-weight: 1000;
  color: #d15428;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 0%;
  margin-top: 10px;
}
.textAvailableUnitModal {
  margin-left: 4%;
  font-size: 75px;
  text-transform: uppercase;
  font-weight: 1000;
  color: #d15428;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 0%;
  margin-top: 10px;
}
.detailsHead {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 600;
  color: #d15428;
  margin-top: 15px;
  margin-bottom: 10px;
}
.detailsHeadModal1 {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 600;
  color: #2e2e2e;
  margin-top: 15px;
  margin-bottom: 10px;
}
.detailsHeadModal2 {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #d15428;
  margin-top: 10px;
  margin-bottom: 5px;
}
.detailContentAvailable {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 300;
}
.shadow1 {
  box-shadow: 0px 0 25px rgba(0, 0, 0, 0.4);
}
.modal {
  /* width:1800px; */
}
.modal-content {
}
.img-wrap {
  width: 100%;
}
.imageAvailable {
  max-width: 100%;
  max-height: 100%;
  display: block;
  min-width: 200px;
  width: 100%;
}
.detailAvailable {
  overflow-y: scroll;
  max-height: 30vmax;
  width: 65%;
}
.detailAvailable::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.detailAvailable::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.detailAvailable::-webkit-scrollbar-thumb {
  background: #f1845c;
}

/* Handle on hover */
.detailAvailable::-webkit-scrollbar-thumb:hover {
  background: #e4b86b;
}
.modal {
  height: fit-content;
}
.bodyAvailable {
}
#exampleModal {
  /* width:1500px; */
}
.availableTable {
  border-top: 1px solid #cfae75;
  border-bottom: 1px solid #cfae75;
  padding-top: 3%;
 
}
.availableTableRow {
  padding-bottom: 4%;
}
.availableUnitTableHead {
  margin-bottom: 1%;
}
.availableTableContent {
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
.availableTableHead {
  margin-left: 4%;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #d15428;
  font-family: "Montserrat", sans-serif;
}
.availableTableHead1 {
  font-size: 15px;
  text-transform: uppercase;
  color: #d15428;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
.availableTableHead2 {
  font-size: 13px;
  text-transform: uppercase;
  color: #383838;
  font-family: sans-serif;
  text-decoration: underline;
  font-family: "Montserrat", sans-serif;
}
.availableUnitSection {
  padding-top: 7%;
}
.availableUnitSection2 {
  padding-top: 15%;
}
.availableUnitTable {
  padding-left: 4%;
  padding-right: 4%;
}
.availableUnitButton {
  padding: 12px 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  border: 3px solid #e4b86b;
  color: #e4b86b;
  margin-top: -100%;
  white-space: nowrap;
}
.primaryAvailableUnitButton {
  padding: 12px 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  border: 3px solid #d15428;
  color: #d15428;
  margin-top: -100%;
}
.primaryAvailableUnitButton:hover {
  background-color: transparent;
  border: 3px solid #d15428;
  color: #d15428;
  text-decoration: none;
  background: rgba(226, 170, 49, 0.2);
}
.availableUnitButton:hover {
  color: #e4b86b;
  background-color: transparent;
  text-decoration: none;
  background: rgba(226, 224, 205, 0.2);
}

.availableUnitButtonModal {
  padding: 12px 20px;
  cursor: pointer;

  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  border: 3px solid #e4b86b;
  color: #e4b86b;
  margin-top: -100%;
  white-space: nowrap;
}
.primaryAvailableUnitButtonModal {
  padding: 12px 20px;
  cursor: pointer;
  margin-left: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  border: 3px solid #d15428;
  color: #d15428;
  margin-top: -100%;
}
.primaryAvailableUnitButtonModal:hover {
  background-color: transparent;
  border: 3px solid #d15428;
  color: #d15428;
  text-decoration: none;
  background: rgba(226, 170, 49, 0.2);
}
.availableUnitButtonModal:hover {
  color: #e4b86b;
  background-color: transparent;
  text-decoration: none;
  background: rgba(226, 224, 205, 0.2);
}
hr.line {
  color: #e4b86b;
  height: 9px;
}
.brownYellow {
  color: #e4b86b;
}
.soraMap{
  min-width:600px;
  min-height:350px;
  border:0;
}
.contactAvailable{
  padding-right:3vmax;
  padding-left:3vmax;
}
@media only screen and (min-height: 1600px) and (max-height: 5000px) {
  .detailAvailable {
    overflow-y: scroll;
    max-height: 55vmax;
    width: 65%;
  }
}
@media only screen and (min-height: 1200px) and (max-height: 1600px) {
  .detailAvailable {
    overflow-y: scroll;
    max-height: 45vmax;
    width: 65%;
  }
}
@media only screen and (min-height: 800px) and (max-height: 1200px) {
  .detailAvailable {
    overflow-y: scroll;
    max-height: 35vmax;
    width: 65%;
  }
}
@media only screen and (min-height: 650px) and (max-height: 800px) {
  .detailAvailable {
    overflow-y: scroll;
    max-height: 31vmax;
    width: 65%;
  }
}
@media only screen and (min-height: 430px) and (max-height: 650px) {
  .detailAvailable {
    overflow-y: scroll;
    max-height: 30vmax;
    width: 65%;
  }
}
@media only screen and (min-height: 300px) and (max-height: 430px) {
  .detailAvailable {
    overflow-y: scroll;
    max-height: 28vmax;
    width: 65%;
  }
}
@media only screen and (min-height: 200px) and (max-height: 300px) {
  .detailAvailable {
    overflow-y: scroll;
    max-height: 25vmax;
    width: 65%;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 5000px) {
  .availableUnitSection2 {
    display: none;
  }
  .availableUnitSection {
    display: block;
  }
  .ModalAvailable {
    height: 100px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 70px;
    margin-top: -20px;
  }
  .imageAvailable {
    min-width: 500px;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1600px) {
  .availableUnitSection2 {
    display: none;
  }
  .availableUnitSection {
    display: block;
  }
  .textAvailableUnit {
    font-size: 55px;
  }
  .ModalAvailable {
    height: 80px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 55px;
    margin-top: -20px;
  }
  .imageAvailable {
    /* min-width:500px; */
  }
  .soraMap{
    min-width:600px;
    height:350px;
    border:0;
  }
}
@media only screen and (min-width: 1040px) and (max-width: 1300px) {
  .availableUnitSection2 {
    display: none;
  }
  .availableUnitSection {
    display: block;
  }
  .availableUnitButton {
    font-size: 10px;
    padding: 10px 15px;
  }
  .primaryAvailableUnitButton {
    font-size: 10px;
    padding: 10px 15px;
  }
  .availableUnitButtonModal {
    font-size: 10px;
    padding: 10px 15px;
  }
  .detailsFooter {
    max-width: 500px;
  }
  .primaryAvailableUnitButtonModal {
    font-size: 10px;
    margin-left: 10px;
    padding: 10px 15px;
  }
  .textAvailableUnit {
    font-size: 55px;
  }
  .ModalAvailable {
    height: 75px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 60px;
    margin-top: -25px;
  }
  .soraMap{
    width:550px;

    border:0;
  }
}

@media only screen and (min-width: 980px) and (max-width: 1040px) {
  .availableUnitSection2 {
    display: none;
  }
  .availableUnitSection {
    display: block;
  }
  .textAvailableUnit {
    font-size: 55px;
  }
  .availableUnitTable {
    padding-left: 0%;
    padding-right: 0%;
  }
  .availableUnitSection {
    padding-top: 10%;
  }
  .availableUnitButton {
    font-size: 8px;
    padding: 8px 10px;
  }
  .detailsFooter {
  }
  .primaryAvailableUnitButton {
    font-size: 8px;
    padding: 8px 10px;
  }
  .availableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
    margin-left: -50px;
  }
  .primaryAvailableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
    margin-left: 5px;
  }
  .ModalAvailable {
    height: 75px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 60px;
    margin-top: -25px;
  }
  .ModalAvailable {
    height: 70px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 45px;
    margin-top: -15px;
  }
  .detailsHead {
    font-size: 15px;
  }
  .detailsHeadModal1 {
    font-size: 22px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .detailsHeadModal2 {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .detailContentAvailable {
    font-size: 18px;
  }
  .soraMap{
    min-width:520px;
    border:0;
  }
}
@media only screen and (min-width: 800px) and (max-width: 980px) {
  .availableUnitSection2 {
    display: none;
  }
  .availableUnitSection {
    display: block;
  }
  .textAvailableUnit {
    font-size: 55px;
  }
  .availableUnitTable {
    padding-left: 0%;
    padding-right: 0%;
  }
  .availableUnitSection {
    padding-top: 10%;
  }
  .availableUnitButton {
    font-size: 8px;
    padding: 8px 10px;
  }
  .detailsFooter {
    max-width: 250px;
  }
  .primaryAvailableUnitButton {
    font-size: 8px;
    padding: 8px 10px;
  }
  .availableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
    margin-left: -50px;
  }
  .primaryAvailableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
    margin-left: 5px;
  }
  .ModalAvailable {
    height: 75px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 60px;
    margin-top: -25px;
  }
  .ModalAvailable {
    height: 70px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 45px;
    margin-top: -15px;
  }
  .detailsHead {
    font-size: 15px;
  }
  .detailsHeadModal1 {
    font-size: 21px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .detailsHeadModal2 {
    font-size: 17px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .detailContentAvailable {
    font-size: 17px;
  }
  .soraMap{
    min-width:420px;
    border:0;
  }
}
@media only screen and (min-width: 650px) and (max-width: 800px) {
  .availableUnitSection2 {
    display: block;
  }
  .availableUnitSection {
    display: none;
  }
  .textAvailableUnit {
    font-size: 45px;
  }
  .availableUnitTable {
    padding-left: 0%;
    padding-right: 0%;
  }
  .availableTableContent {
    line-height: 10px;
  }
  .availableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .primaryAvailableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .detailsFooter {
    min-width: 450px;
  }
  .availableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
  }
  .primaryAvailableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
    margin-left: 20px;
  }
  .ModalAvailable {
    height: 70px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 45px;
    margin-top: -15px;
  }
  .detailsHeadModal1 {
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .detailsHeadModal2 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .detailContentAvailable {
    font-size: 16px;
  }
  .soraMap{
    min-width:650px;
    min-height:300px;
    border:0;
  }
  
}

@media only screen and (min-width: 500px) and (max-width: 650px) {
  .availableUnitSection2 {
    display: block;
  }
  .availableUnitSection {
    display: none;
  }
  .textAvailableUnit {
    font-size: 45px;
  }
  .availableUnitTable {
    padding-left: 0%;
    padding-right: 0%;
  }
  .availableTableContent {
    line-height: 10px;
  }
  .availableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .primaryAvailableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .detailsFooter {
    min-width: 450px;
  }
  .availableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
  }
  .primaryAvailableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
    margin-left: 10px;
  }
  .ModalAvailable {
    height: 70px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 45px;
    margin-top: -15px;
  }
  .detailsHeadModal1 {
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .detailsHeadModal2 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .detailContentAvailable {
    font-size: 16px;
  }
  .soraMap{
    min-width:500px;
    min-height:300px;
    border:0;
  }
  
}
@media only screen and (min-width: 400px) and (max-width: 500px) {
  .availableUnitSection2 {
    padding-top: 20%;
  }
  .availableUnitSection2 {
    display: block;
  }
  .availableUnitSection {
    display: none;
  }
  .textAvailableUnit {
    font-size: 30px;
  }
  .availableUnitTable {
    padding-left: 0%;
    padding-right: 0%;
  }
  .availableTableContent {
    line-height: 10px;
  }
  .availableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .primaryAvailableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .detailsFooter {
    min-width: 370px;
  }
  .availableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
  }
  .primaryAvailableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
    margin-left: 10px;
  }
  .ModalAvailable {
    height: 70px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 40px;
    margin-top: -10px;
  }
  .detailsHead {
    font-size: 18px;
  }
  .detailAvailable {
   
    min-width: 400px;
    width: 100%;
  }
  .detailContentAvailable {
    font-size: 15px;
  }
  p {
    font-size: 12px;
  }
  .imageAvailable {
    min-width: 200px;
  }

  .detailsHeadModal1 {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .detailsHeadModal2 {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .detailContentAvailable {
    font-size: 14px;
  }
  .soraMap{
    min-width:400px;
    min-height:250px;
    border:0;
  }
}
@media only screen and (min-width: 300px) and (max-width: 400px) {
  .availableUnitSection2 {
    padding-top: 30%;
  }
  .availableUnitSection2 {
    display: block;
  }
  .availableUnitSection {
    display: none;
  }
  .textAvailableUnit {
    font-size: 30px;
  }
  .availableUnitTable {
    padding-left: 0%;
    padding-right: 0%;
  }
  .availableTableContent {
    line-height: 10px;
  }
  .availableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .primaryAvailableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .detailsFooter {
    min-width: 290px;
  }
  .detailsHead {
    font-size: 17px;
  }
  .availableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
  }
  .primaryAvailableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
    margin-left: 10px;
  }
  .ModalAvailable {
    height: 50px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 25px;
    margin-top: -5px;
  }
  .detailAvailable {

    min-width: 300px;
    width: 100%;
  }
  .detailContentAvailable {
    font-size: 13px;
  }
  p {
    font-size: 13px;
  }
  .imageAvailable {
    min-width: 200px;
  }

  .detailsHeadModal1 {
    font-size: 17px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .detailsHeadModal2 {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .detailContentAvailable {
    font-size: 13px;
  }
  .soraMap{
    min-width:300px;
    min-height:250px;
    border:0;
  }
}
@media only screen and (min-width: 200px) and (max-width: 300px) {
  .availableUnitSection2 {
    padding-top: 35%;
  }
  .availableUnitSection2 {
    display: block;
  }
  .availableUnitSection {
    display: none;
  }
  .textAvailableUnit {
    font-size: 25px;
  }
  .availableUnitTable {
    padding-left: 0%;
    padding-right: 0%;
  }
  .availableTableContent {
    line-height: 10px;
  }
  .availableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .primaryAvailableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .ModalAvailable {
    height: 50px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 25px;
    margin-top: -5px;
  }
  .detailsFooter {
    min-width: 210px;
  }
  .detailsHead {
    font-size: 17px;
  }
  .availableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
  }
  .primaryAvailableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
    margin-left: 10px;
  }
  .detailAvailable {
   
    min-width: 200px;
    width: 100%;
  }
  .detailContentAvailable {
    font-size: 12px;
  }
  p {
    font-size: 12px;
  }
  .imageAvailable {
    min-width: 200px;
  }

  .detailsHeadModal1 {
    font-size: 17px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .detailsHeadModal2 {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .detailContentAvailable {
    font-size: 13px;
  }
  .soraMap{
    min-width:300px;
    min-height:200px;
    border:0;
  }
}
