.availables {
  width: 101%;
  z-index: 2;
  /* margin:0 auto; */
  margin-left: 20.5%;
  /* display: inline-block; */
}
.availables1 {
  z-index: 2;
  margin-bottom: 10%;
  float: right;
}
.availables2 {
  width: 121%;
  z-index: 2;
  margin-bottom: 10%;
}
.AvailableSection {
  position: static;
  margin-left: -1.1%;
}
.mainPositionAvailable {
  padding-top: 28%;
  padding-left: 5%;
  margin-right: 5px;
  margin-top: -4%;
  z-index: 3;
  color: #f7d000;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 110px;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 100%;
}
.availablePoster {
  background-color: #f4f4ef;
  display: block;
  z-index: 1;
  width: 56%;
  margin-left: 0%;
  padding-top: 5%;
  margin-top: 25%;
  padding-top: 5%;
  padding-left: 4%;
  padding-right: 25%;
  padding-bottom: 3%;
}
.contentButton1{
  padding: 16px 90px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  background-color: transparent;
  border: 3px solid #d15428 !important;
  color: #d15428 !important;
}
.contentButton1:hover {
  background: rgba(84, 132, 177, 0.1);
}
.availableContent {
  margin-top: 5%;
  margin-bottom: 8%;
}
.availableContent2 {
  background-color: #f4f4ef;
  padding-left: 10%;
  padding-top: 3%;
  padding-right: 28%;
  padding-bottom: 8%;
}
.homeBack1 {
  height: 75%;
}
.availableBackImage {
  background-image: url("./images/availables.jpg");
  background-position: center;
  background-size: cover;
  height: 60%;
  background-position: 50% 50%;

  position: static;
  top: -6.25px;
  left: -6.25px;
  bottom: -6.25px;
  right: -6.25px;
  padding-left: 5%;
  padding-top: 100%;
}
.mainTextAvailable {
  font-family: "Roboto Condensed", sans-serif;
  color: #f7d000;
  font-size: 80px;
  text-transform: uppercase;
  font-weight: 900;
  padding-bottom: 2%;
  line-height: 100%;
  margin-top: -8%;
}
.contentTextAvailable {
  padding-bottom: 2%;
  font-family: sans-serif;
  color: rgb(20, 18, 18);
  font-size: 18px;
}
.contentText {
  padding-bottom: 1%;
}
.homeText {
  padding-top: 5%;
}
.homeSection {
  height: 90%;
  margin-bottom: -50%;
}
@media only screen and (min-device-width: 1226px) and (max-device-width: 3226px) {
  .homeSection {
    height: 85%;
    margin-bottom: -20%;
  }
  .available {
    display: block;
  }
  .available1 {
    display: none;
  }
  .mainPositionAvailable {
    padding-top: 22%;
    padding-left: 15%;
  }
  .availablePoster {
    width: 56%;
    margin-left: 0%;
    margin-top: 27%;
    padding-top: 9%;
    padding-left: 4%;
    padding-right: 25%;
    padding-bottom: 3%;
  }
  .availableContent {
    margin-top: 5%;
    margin-bottom: 8%;
  }
}
@media only screen and (min-device-width: 1020px) and (max-device-width: 1250px) {
  .available {
    display: block;
  }
  .available1 {
    display: none;
  }
  .mainPositionAvailable {
    padding-top: 17%;
  }
  .availablePoster {
    width: 56%;
    margin-left: 0%;
    margin-top: 25%;
    padding-top: 5%;
    padding-left: 4%;
    padding-right: 25%;
    padding-bottom: 3%;
  }
  .availableContent {
    margin-top: 5%;
    margin-bottom: 8%;
  }
}
@media only screen and (min-device-width: 900px) and (max-device-width: 1020px) {
  .available {
    display: block;
  }
  .available1 {
    display: none;
  }
  .mainPositionAvailable {
    padding-top: 15%;
  }
  .availablePoster {
    width: 56%;
    margin-left: 0%;
    margin-top: 25%;
    padding-top: 5%;
    padding-left: 4%;
    padding-right: 25%;
    padding-bottom: 3%;
  }
  .availableContent {
    margin-top: 5%;
    margin-bottom: 8%;
    padding-right: 10%;
  }
}

@media only screen and (min-device-width: 700px) and (max-device-width: 900px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 80%;
    margin-bottom: 5%;
  }
}
@media only screen and (min-device-width: 500px) and (max-device-width: 700px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 70%;
    margin-bottom: 3%;
  }
  .mainTextAvailable {
    font-size: 65px;
    line-height: 100%;
  }
  .contentTextAvailable {
    font-size: 22px;
  }
  .contentButton1 {
    padding: 12px 60px;
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 500px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 60%;
    margin-bottom: 7%;
  }
  .mainTextAvailable {
    font-size: 45px;
    line-height: 100%;
  }
  .contentTextAvailable {
    font-size: 18px;
  }
  .contentButton1 {
    padding: 8px 40px;
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 200px) and (max-device-width: 300px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 55%;
    margin-bottom: 3%;
  }
  .mainTextAvailable {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextAvailable {
    font-size: 16px;
  }
  .contentButton1 {
    padding: 5px 20px;
    font-size: 11px;
  }
}
@media only screen and (min-device-width: 100px) and (max-device-width: 200px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 25%;
    margin-bottom: 3%;
  }
  .mainTextAvailable {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextAvailable {
    font-size: 16px;
  }
  .contentButton1 {
    padding: 5px 20px;
    font-size: 11px;
  }
}

@media only screen and (min-width: 1226px) and (max-width: 3226px) {
  .homeSection {
    height: 85%;
    margin-bottom: -20%;
  }
  .available {
    display: block;
  }
  .available1 {
    display: none;
  }
}
@media only screen and (min-width: 1020px) and (max-width: 1250px) {
  .available {
    display: block;
  }
  .available1 {
    display: none;
  }
  .mainPositionAvailable {
    padding-top: 13%;
  }
  .availables {
    margin-top: 5%;
  }
  .availablePoster {
    width: 56%;
    margin-left: 0%;
    margin-top: 22%;
    padding-top: 5%;
    padding-left: 4%;
    padding-right: 25%;
    padding-bottom: 3%;
  }
  .availableContent {
    margin-top: 10%;
    margin-bottom: 12%;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1020px) {
  .available {
    display: block;
  }
  .available1 {
    display: none;
  }
  .mainPositionAvailable {
    padding-top: 15%;
  }
  .availablePoster {
    width: 56%;
    margin-left: 0%;
    margin-top: 25%;
    padding-top: 10%;
    padding-left: 4%;
    padding-right: 25%;
    padding-bottom: 3%;
  }
  .availableContent {
    margin-top: 5%;
    margin-bottom: 8%;
    padding-right: 10%;
  }
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 80%;
    margin-bottom: 5%;
  }
}
@media only screen and (min-width: 500px) and (max-width: 700px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 70%;
    margin-bottom: 3%;
  }
  .mainTextAvailable {
    font-size: 65px;
    line-height: 100%;
  }
  .contentTextAvailable {
    font-size: 22px;
  }
  .contentButton1 {
    padding: 12px 60px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 500px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 60%;
    margin-bottom: 7%;
  }
  .mainTextAvailable {
    font-size: 45px;
    line-height: 100%;
  }
  .contentTextAvailable {
    font-size: 18px;
  }
  .contentButton1 {
    padding: 8px 40px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 300px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 55%;
    margin-bottom: 3%;
  }
  .mainTextAvailable {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextAvailable {
    font-size: 16px;
  }
  .contentButton1 {
    padding: 5px 20px;
    font-size: 11px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 200px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 25%;
    margin-bottom: 3%;
  }
  .mainTextAvailable {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextAvailable {
    font-size: 16px;
  }
  .contentButton1 {
    padding: 5px 20px;
    font-size: 11px;
  }
}
