@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
/* background-color: black; */
    padding-right: 0%;
    padding-left: 0%;
}

.InterestedSection {
  padding: 6%;
  
  align-items: center;
}
.Box {
  border: 10px solid #d15428;
  padding-top: 7%;
  padding-bottom: 7%;
}
.interestHeading {
  font-family: "Roboto Condensed", sans-serif;
  color: #d15428;
  font-size: 105px;
  text-transform: uppercase;
  font-weight: 1000;
  padding-bottom: 2%;
  line-height: 100%;
}
.interestContent {
  font-family: sans-serif;
  color: #000;
  font-size: 25px;
  line-height: 100%;
  padding-bottom: 2%;
}
@media only screen and (min-device-width: 2020px) and (max-device-width: 3250px) {
  .interestHeading {
    font-size: 175px;
  }
  .interestContent {
    font-size: 55px;
  }
}
@media only screen and (min-device-width: 1000px) and (max-device-width: 2020px) {
  .interestHeading {
    font-size: 110px;
  }
  .interestContent {
    font-size: 45px;
  }
}
@media only screen and (min-device-width: 800px) and (max-device-width: 1000px) {
  .interestHeading {
    font-size: 80px;
  }
  .interestContent {
    font-size: 35px;
  }
}
@media only screen and (min-device-width: 600px) and (max-device-width: 800px) {
  .interestHeading {
    font-size: 70px;
  }
  .interestContent {
    font-size: 30px;
  }
  .Box {
    padding-bottom: 7%;
  }
}
@media only screen and (min-device-width: 400px) and (max-device-width: 600px) {
  .interestHeading {
    font-size: 50px;
  }
  .interestContent {
    font-size: 25px;
  }
  .Box {
    padding-bottom: 7%;
  }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 400px) {
  .interestHeading {
    font-size: 40px;
  }
  .interestContent {
    font-size: 20px;
  }
  .Box {
    padding-bottom: 7%;
  }
}
@media only screen and (min-device-width: 200px) and (max-device-width: 300px) {
  .interestHeading {
    font-size: 30px;
  }
  .interestContent {
    font-size: 15px;
  }
  .Box {
    padding-bottom: 7%;
  }
}


@media only screen and (min-width: 2020px) and (max-width: 3250px) {
  .interestHeading {
    font-size: 175px;
  }
  .interestContent {
    font-size: 55px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 2020px) {
  .interestHeading {
    font-size: 110px;
  }
  .interestContent {
    font-size: 45px;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .interestHeading {
    font-size: 80px;
  }
  .interestContent {
    font-size: 35px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 800px) {
  .interestHeading {
    font-size: 70px;
  }
  .interestContent {
    font-size: 30px;
  }
  .Box {
    padding-bottom: 7%;
  }
}
@media only screen and (min-width: 400px) and (max-width: 600px) {
  .interestHeading {
    font-size: 50px;
  }
  .interestContent {
    font-size: 25px;
  }
  .Box {
    padding-bottom: 7%;
  }
}
@media only screen and (min-width: 300px) and (max-width: 400px) {
  .interestHeading {
    font-size: 40px;
  }
  .interestContent {
    font-size: 20px;
  }
  .Box {
    padding-bottom: 7%;
  }
}
@media only screen and (min-width: 200px) and (max-width: 300px) {
  .interestHeading {
    font-size: 30px;
  }
  .interestContent {
    font-size: 15px;
  }
  .Box {
    padding-bottom: 7%;
  }
}

.availables {
  width: 101%;
  z-index: 2;
  /* margin:0 auto; */
  margin-left: 20.5%;
  /* display: inline-block; */
}
.availables1 {
  z-index: 2;
  margin-bottom: 10%;
  float: right;
}
.availables2 {
  width: 121%;
  z-index: 2;
  margin-bottom: 10%;
}
.AvailableSection {
  position: static;
  margin-left: -1.1%;
}
.mainPositionAvailable {
  padding-top: 28%;
  padding-left: 5%;
  margin-right: 5px;
  margin-top: -4%;
  z-index: 3;
  color: #f7d000;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 110px;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 100%;
}
.availablePoster {
  background-color: #f4f4ef;
  display: block;
  z-index: 1;
  width: 56%;
  margin-left: 0%;
  padding-top: 5%;
  margin-top: 25%;
  padding-top: 5%;
  padding-left: 4%;
  padding-right: 25%;
  padding-bottom: 3%;
}
.contentButton1{
  padding: 16px 90px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  background-color: transparent;
  border: 3px solid #d15428 !important;
  color: #d15428 !important;
}
.contentButton1:hover {
  background: rgba(84, 132, 177, 0.1);
}
.availableContent {
  margin-top: 5%;
  margin-bottom: 8%;
}
.availableContent2 {
  background-color: #f4f4ef;
  padding-left: 10%;
  padding-top: 3%;
  padding-right: 28%;
  padding-bottom: 8%;
}
.homeBack1 {
  height: 75%;
}
.availableBackImage {
  background-image: url(/static/media/availables.e169e1eb.jpg);
  background-position: center;
  background-size: cover;
  height: 60%;
  background-position: 50% 50%;

  position: static;
  top: -6.25px;
  left: -6.25px;
  bottom: -6.25px;
  right: -6.25px;
  padding-left: 5%;
  padding-top: 100%;
}
.mainTextAvailable {
  font-family: "Roboto Condensed", sans-serif;
  color: #f7d000;
  font-size: 80px;
  text-transform: uppercase;
  font-weight: 900;
  padding-bottom: 2%;
  line-height: 100%;
  margin-top: -8%;
}
.contentTextAvailable {
  padding-bottom: 2%;
  font-family: sans-serif;
  color: rgb(20, 18, 18);
  font-size: 18px;
}
.contentText {
  padding-bottom: 1%;
}
.homeText {
  padding-top: 5%;
}
.homeSection {
  height: 90%;
  margin-bottom: -50%;
}
@media only screen and (min-device-width: 1226px) and (max-device-width: 3226px) {
  .homeSection {
    height: 85%;
    margin-bottom: -20%;
  }
  .available {
    display: block;
  }
  .available1 {
    display: none;
  }
  .mainPositionAvailable {
    padding-top: 22%;
    padding-left: 15%;
  }
  .availablePoster {
    width: 56%;
    margin-left: 0%;
    margin-top: 27%;
    padding-top: 9%;
    padding-left: 4%;
    padding-right: 25%;
    padding-bottom: 3%;
  }
  .availableContent {
    margin-top: 5%;
    margin-bottom: 8%;
  }
}
@media only screen and (min-device-width: 1020px) and (max-device-width: 1250px) {
  .available {
    display: block;
  }
  .available1 {
    display: none;
  }
  .mainPositionAvailable {
    padding-top: 17%;
  }
  .availablePoster {
    width: 56%;
    margin-left: 0%;
    margin-top: 25%;
    padding-top: 5%;
    padding-left: 4%;
    padding-right: 25%;
    padding-bottom: 3%;
  }
  .availableContent {
    margin-top: 5%;
    margin-bottom: 8%;
  }
}
@media only screen and (min-device-width: 900px) and (max-device-width: 1020px) {
  .available {
    display: block;
  }
  .available1 {
    display: none;
  }
  .mainPositionAvailable {
    padding-top: 15%;
  }
  .availablePoster {
    width: 56%;
    margin-left: 0%;
    margin-top: 25%;
    padding-top: 5%;
    padding-left: 4%;
    padding-right: 25%;
    padding-bottom: 3%;
  }
  .availableContent {
    margin-top: 5%;
    margin-bottom: 8%;
    padding-right: 10%;
  }
}

@media only screen and (min-device-width: 700px) and (max-device-width: 900px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 80%;
    margin-bottom: 5%;
  }
}
@media only screen and (min-device-width: 500px) and (max-device-width: 700px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 70%;
    margin-bottom: 3%;
  }
  .mainTextAvailable {
    font-size: 65px;
    line-height: 100%;
  }
  .contentTextAvailable {
    font-size: 22px;
  }
  .contentButton1 {
    padding: 12px 60px;
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 500px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 60%;
    margin-bottom: 7%;
  }
  .mainTextAvailable {
    font-size: 45px;
    line-height: 100%;
  }
  .contentTextAvailable {
    font-size: 18px;
  }
  .contentButton1 {
    padding: 8px 40px;
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 200px) and (max-device-width: 300px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 55%;
    margin-bottom: 3%;
  }
  .mainTextAvailable {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextAvailable {
    font-size: 16px;
  }
  .contentButton1 {
    padding: 5px 20px;
    font-size: 11px;
  }
}
@media only screen and (min-device-width: 100px) and (max-device-width: 200px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 25%;
    margin-bottom: 3%;
  }
  .mainTextAvailable {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextAvailable {
    font-size: 16px;
  }
  .contentButton1 {
    padding: 5px 20px;
    font-size: 11px;
  }
}

@media only screen and (min-width: 1226px) and (max-width: 3226px) {
  .homeSection {
    height: 85%;
    margin-bottom: -20%;
  }
  .available {
    display: block;
  }
  .available1 {
    display: none;
  }
}
@media only screen and (min-width: 1020px) and (max-width: 1250px) {
  .available {
    display: block;
  }
  .available1 {
    display: none;
  }
  .mainPositionAvailable {
    padding-top: 13%;
  }
  .availables {
    margin-top: 5%;
  }
  .availablePoster {
    width: 56%;
    margin-left: 0%;
    margin-top: 22%;
    padding-top: 5%;
    padding-left: 4%;
    padding-right: 25%;
    padding-bottom: 3%;
  }
  .availableContent {
    margin-top: 10%;
    margin-bottom: 12%;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1020px) {
  .available {
    display: block;
  }
  .available1 {
    display: none;
  }
  .mainPositionAvailable {
    padding-top: 15%;
  }
  .availablePoster {
    width: 56%;
    margin-left: 0%;
    margin-top: 25%;
    padding-top: 10%;
    padding-left: 4%;
    padding-right: 25%;
    padding-bottom: 3%;
  }
  .availableContent {
    margin-top: 5%;
    margin-bottom: 8%;
    padding-right: 10%;
  }
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 80%;
    margin-bottom: 5%;
  }
}
@media only screen and (min-width: 500px) and (max-width: 700px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 70%;
    margin-bottom: 3%;
  }
  .mainTextAvailable {
    font-size: 65px;
    line-height: 100%;
  }
  .contentTextAvailable {
    font-size: 22px;
  }
  .contentButton1 {
    padding: 12px 60px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 500px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 60%;
    margin-bottom: 7%;
  }
  .mainTextAvailable {
    font-size: 45px;
    line-height: 100%;
  }
  .contentTextAvailable {
    font-size: 18px;
  }
  .contentButton1 {
    padding: 8px 40px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 300px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 55%;
    margin-bottom: 3%;
  }
  .mainTextAvailable {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextAvailable {
    font-size: 16px;
  }
  .contentButton1 {
    padding: 5px 20px;
    font-size: 11px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 200px) {
  .available {
    display: none;
  }
  .available1 {
    display: block;
  }
  .homeSection {
    height: 25%;
    margin-bottom: 3%;
  }
  .mainTextAvailable {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextAvailable {
    font-size: 16px;
  }
  .contentButton1 {
    padding: 5px 20px;
    font-size: 11px;
  }
}

.willetBody {
  background-color: rgb(255, 255, 255);
  height: 1000px;
}
.hide {
  display: none;
}
.primaryBackground {
  background-color: #d15428;
}
.primaryText {
  color: #d15428;
}
.mainText {
  /* font-family: "Bourton W00", sans-serif; */
  font-family: "Roboto Condensed", sans-serif;
  color: #fff;
  font-size: 110px;
  text-transform: uppercase;
  font-weight: 900;
  padding-top: 5%;
  line-height: 100%;
}
.contentText {
  font-family: "Roboto Condensed", sans-serif;
  color: #fff;
  font-size: 30px;
  /* padding-bottom:3%; */
  font-weight: 900;
}

.posterText {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 10;
  color: #000;
  font-weight: 590;
}
.whiteButton {
  cursor: pointer;
  padding: 16px 90px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  background-color: transparent;
  border: 3px solid #ffffff;
  color: #fff;
}
.whiteButton:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  text-decoration: none;
}
.primaryButton {
  cursor: pointer;
  padding: 16px 90px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  background-color: transparent;
  border: 3px solid #d15428;
  color: #d15428;
}
.primaryButton.pb {
  padding: 14px 30px;
}
.primaryButton:hover {
  background: rgba(231, 145, 15, 0.1);
  color: #d15428;
  text-decoration: none;
}
.bgWhite {
  background-color: #fff;
}
.textWhite {
  color: #fff;
}
.bgPrimary {
  background-color: #d15428;
}
.textPrimary {
  color: #d15428;
}
.absolute {
  position: absolute;
}
@media only screen and (min-device-width: 1020px) and (max-device-width: 1250px) {
  .mainText {
    font-size: 105px;
    font-weight: 900;
    padding-top: 5%;
    line-height: 90%;
  }
  .posterText {
    font-size: 20px;
    font-weight: 590;
  }
}
@media only screen and (min-device-width: 900px) and (max-device-width: 1020px) {
  .mainText {
    font-size: 92px;
    font-weight: 900;
    padding-top: 5%;
    line-height: 90%;
  }
  .posterText {
    font-size: 18px;
    font-weight: 590;
  }
  .primaryButton {
    padding: 13px 80px;
  }
}
@media only screen and (min-device-width: 600px) and (max-device-width: 900px) {
  .mainText {
    font-size: 60px;
    font-weight: 900;
    padding-top: 5%;
    line-height: 90%;
  }
  .posterText {
    font-size: 16px;
    font-weight: 590;
  }
  .primaryButton {
    padding: 10px 60px;
  }
}
@media only screen and (min-device-width: 500px) and (max-device-width: 600px) {
  .mainText {
    font-size: 55px;
    font-weight: 900;
    padding-top: 5%;
    line-height: 90%;
  }
  .posterText {
    font-size: 11px;
    font-weight: 590;
  }
  .primaryButton {
    padding: 8px 30px;
  }
}
@media only screen and (min-device-width: 420px) and (max-device-width: 500px) {
  .mainText {
    font-size: 85px;
    font-weight: 900;
    padding-top: 5%;
    line-height: 90%;
  }
  .posterText {
    font-size: 18px;
    font-weight: 590;
  }
  .primaryButton {
    padding: 8px 30px;
  }
}
@media only screen and (min-device-width: 330px) and (max-device-width: 420px) {
  .mainText {
    font-size: 65px;
    font-weight: 900;
    padding-top: 5%;
    line-height: 90%;
  }
  .posterText {
    font-size: 16px;
    font-weight: 590;
  }
  .primaryButton {
    padding: 8px 20px;
  }
}
@media only screen and (min-device-width: 100px) and (max-device-width: 330px) {
  .mainText {
    font-size: 45px;
    font-weight: 900;
    padding-top: 5%;
    line-height: 90%;
  }
  .posterText {
    font-size: 14px;
    font-weight: 590;
  }
  .primaryButton {
    padding: 8px 10px;
  }
}
.opacityAnimation1 {
  animation: fadeInFromNone 3s ease-in;
}
.opacityAnimation {
  animation: fadeInFromNone 3s ease-out;
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.building {
  width: 435%;
  z-index: 2;
  margin: 0 auto;

  /* display: inline-block; */
}
.building1 {
  z-index: 2;
  margin-bottom: 10%;
}
.buildingSection {
  position: static;
  margin-left: -1.1%;
}
.mainPosition {
  padding-top: 28%;
  padding-left: 53%;
  margin-right: 5px;
  z-index: 3;
}
.buildingPoster {
  background-color: #f4f4ef;
  float: right;
  display: block;
  z-index: 1;
  width: 66%;
  margin-left: 32%;
  padding-top: 5%;
  margin-top: 48%;
  padding-top: 5%;
  padding-left: 30%;
  padding-right: 2%;
  padding-bottom: 4%;
}
.contentButton {
  padding: 16px 90px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  background-color: transparent;
  border: 3px solid #d15428;
  color: #d15428;
}
.contentButton:hover {
  background: rgba(84, 132, 177, 0.1);
}
.buildContent {
  margin-top: 5%;
  margin-bottom: 10%;
}
.buildContent2 {
  background-color: #f4f4ef;
  padding-left: 10%;
  padding-top: 3%;
  padding-right: 28%;
  padding-bottom: 8%;
}
.homeBack1 {
  height: 75%;
}
.buildBackImage {
  background-image: url(/static/media/soraImage2.08fda069.jpg);
  background-position: center;
  background-size: cover;
  height: 60%;
  background-position: 50% 50%;

  position: static;
  top: -6.25px;
  left: -6.25px;
  bottom: -6.25px;
  right: -6.25px;
  padding-left: 5%;
  padding-top: 100%;
}
.mainTextBuild {
  font-family: "Roboto Condensed", sans-serif;
  color: #d15428;
  font-size: 80px;
  text-transform: uppercase;
  font-weight: 900;
  padding-bottom: 2%;
  line-height: 100%;
  margin-top: -8%;
}
.contentTextBuilt {
  padding-bottom: 2%;
  font-family: sans-serif;
  color: rgb(20, 18, 18);
  font-size: 18px;
}
.contentText {
  padding-bottom: 1%;
}
.homeText {
  padding-top: 5%;
}
.homeSection {
  height: 90%;
  margin-bottom: -50%;
}
@media only screen and (min-device-width: 1550px) and (max-device-width: 3226px) {
  .homeSection {
    height: 85%;
    margin-bottom: -20%;
  }
  .build {
    display: block;
  }
  .build1 {
    display: none;
  }
  .mainPosition {
    padding-top: 26%;
    padding-left: 60%;
  }
  .buildingPoster {
    padding-top: 7%;
    padding-left: 45%;
    padding-right: 5%;
    margin-top: 46%;
    width: 77%;
    padding-bottom: 10%;
    margin-left: 21%;
  }
  .buildContent {
    margin-top: 5%;
    margin-bottom: 19%;
  }
}
@media only screen and (min-device-width: 1250px) and (max-device-width: 1550px) {
  .homeSection {
    height: 85%;
    margin-bottom: -20%;
  }
  .build {
    display: block;
  }
  .build1 {
    display: none;
  }
  .mainPosition {
    padding-top: 26%;
    padding-left: 60%;
  }
  .buildingPoster {
    padding-top: 7%;
    padding-left: 40%;
    padding-right: 5%;
    margin-top: 42%;
    width: 77%;
    padding-bottom: 5%;
    margin-left: 21%;
  }
  .buildContent {
    margin-top: 5%;
    margin-bottom: 19%;
  }
}
@media only screen and (min-device-width: 1020px) and (max-device-width: 1250px) {
  .build {
    display: block;
  }
  .build1 {
    display: none;
  }
  .mainPosition {
    padding-top: 17%;
  }
  .buildingPoster {
    padding-top: 7%;
    padding-left: 34%;
    padding-right: 5%;
    margin-top: 35%;
    width: 77%;
    padding-bottom: 5%;
    margin-left: 23%;
  }
  .buildContent {
    margin-top: 5%;
    margin-bottom: 19%;
  }
}
@media only screen and (min-device-width: 900px) and (max-device-width: 1020px) {
  .build {
    display: block;
  }
  .build1 {
    display: none;
  }
  .mainPosition {
    padding-top: 15%;
  }
  .buildingPoster {
    margin-top: 36%;
    padding-top: 4%;
    padding-left: 35%;
    padding-right: 2%;
    width: 82%;
    margin-left: 19%;
    padding-bottom: 5%;
  }
  .buildContent {
    margin-top: 1%;
    margin-bottom: 12%;
    padding-right: 10%;
  }
}

@media only screen and (min-device-width: 700px) and (max-device-width: 900px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 80%;
    margin-bottom: 5%;
  }
}
@media only screen and (min-device-width: 500px) and (max-device-width: 700px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 70%;
    margin-bottom: 3%;
  }
  .mainTextBuild {
    font-size: 65px;
    line-height: 100%;
  }
  .contentTextBuilt {
    font-size: 22px;
  }
  .contentButton {
    padding: 12px 60px;
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 500px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 60%;
    margin-bottom: 7%;
  }
  .mainTextBuild {
    font-size: 45px;
    line-height: 100%;
  }
  .contentTextBuilt {
    font-size: 18px;
  }
  .contentButton {
    padding: 8px 40px;
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 200px) and (max-device-width: 300px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 55%;
    margin-bottom: 3%;
  }
  .mainTextBuild {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextBuilt {
    font-size: 16px;
  }
  .contentButton {
    padding: 5px 20px;
    font-size: 11px;
  }
}
@media only screen and (min-device-width: 100px) and (max-device-width: 200px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 25%;
    margin-bottom: 3%;
  }
  .mainTextBuild {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextBuilt {
    font-size: 16px;
  }
  .contentButton {
    padding: 5px 20px;
    font-size: 11px;
  }
}

@media only screen and (min-width: 1250px) and (max-width: 3226px) {
  .homeSection {
    height: 85%;
    margin-bottom: -20%;
  }
  .build {
    display: block;
  }
  .build1 {
    display: none;
  }
  .buildingSection {
    margin-bottom: 15%;
  }
}
@media only screen and (min-width: 1020px) and (max-width: 1250px) {
  .build {
    display: block;
  }
  .build1 {
    display: none;
  }
  .mainPosition {
    padding-top: 17%;
  }
  .buildingPoster {
    padding-top: 7%;
    padding-left: 34%;
    padding-right: 5%;
    margin-top: 35%;
    width: 77%;
    padding-bottom: 5%;
    margin-left: 23%;
  }
  .buildContent {
    margin-top: 5%;
    margin-bottom: 19%;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1020px) {
  .build {
    display: block;
  }
  .build1 {
    display: none;
  }
  .mainPosition {
    padding-top: 15%;
  }
  .buildingPoster {
    margin-top: 36%;
    padding-top: 4%;
    padding-left: 35%;
    padding-right: 2%;
    width: 82%;
    margin-left: 19%;
    padding-bottom: 5%;
  }
  .buildContent {
    margin-top: 1%;
    margin-bottom: 12%;
    padding-right: 10%;
  }
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 80%;
    margin-bottom: 5%;
  }
}
@media only screen and (min-width: 500px) and (max-width: 700px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 70%;
    margin-bottom: 3%;
  }
  .mainTextBuild {
    font-size: 65px;
    line-height: 100%;
  }
  .contentTextBuilt {
    font-size: 22px;
  }
  .contentButton {
    padding: 12px 60px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 500px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 60%;
    margin-bottom: 7%;
  }
  .mainTextBuild {
    font-size: 45px;
    line-height: 100%;
  }
  .contentTextBuilt {
    font-size: 18px;
  }
  .contentButton {
    padding: 8px 40px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 300px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 55%;
    margin-bottom: 3%;
  }
  .mainTextBuild {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextBuilt {
    font-size: 16px;
  }
  .contentButton {
    padding: 5px 20px;
    font-size: 11px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 200px) {
  .build {
    display: none;
  }
  .build1 {
    display: block;
  }
  .homeSection {
    height: 25%;
    margin-bottom: 3%;
  }
  .mainTextBuild {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextBuilt {
    font-size: 16px;
  }
  .contentButton {
    padding: 5px 20px;
    font-size: 11px;
  }
}

.homeBack {
  background-image: url(/static/media/sora.3c59d859.jpg);
  background-position: 0px -200px;
  background-size: cover;
  background-repeat: no-repeat;
  height: 60%;
  padding-left: 5%;
  padding-right: 20%;
  padding-top: 3%;
}
.homeContent {
  padding-left: 3%;
  padding-top: 3%;
  padding-right: 3%;
  padding-bottom: 3%;
}
.homeBack1 {
  height: 75%;
}
.backImage {
  background-image: url(/static/media/sora.3c59d859.jpg);
  background-position: center;
  background-size: cover;
  height: 60%;
  background-position: 50% 50%;

  position: static;
  top: -6.25px;
  left: -6.25px;
  bottom: -6.25px;
  right: -6.25px;
  padding-left: 5%;
  padding-top: 40%;
}

.mainTextHome {
  font-family: "Roboto Condensed", sans-serif;
  color: #fff;
  font-size: 80px;
  text-transform: uppercase;
  font-weight: 900;
  padding-bottom: 2%;
  line-height: 100%;
}
.contentTextHome {
  padding-bottom: 2%;
  font-family: "Roboto Condensed", sans-serif;
  color: #fff;
  font-size: 28px;

  font-weight: 900;
}
.contentText {
  padding-bottom: 1%;
}
.homeText {
  padding-top: 5%;
}
.homeSection {
  height: 90%;
  margin-bottom: -50%;
}
@media only screen and (min-device-width: 1526px) and (max-device-width: 3226px) {
  .homeSection {
    height: 140%;
    margin-bottom: -20%;
  }
  .homeBack {
    display: block;
  }
  .homeBack1 {
    display: none;
  }
}
@media only screen and (min-device-width: 1226px) and (max-device-width: 1526px) {
  .homeSection {
    height: 140%;
    margin-bottom: -30%;
  }
  .homeBack {
    display: block;
  }
  .homeBack1 {
    display: none;
  }
}
@media only screen and (min-device-width: 1130px) and (max-device-width: 1226px) {
  .homeSection {
    height: 85%;
    margin-bottom: -20%;
  }
  .homeBack {
    display: block;
  }
  .homeBack1 {
    display: none;
  }
}
@media only screen and (min-device-width: 900px) and (max-device-width: 1130px) {
  .homeBack {
    display: block;
  }
  .homeBack1 {
    display: none;
  }
  .homeSection {
    height: 70%;
    margin-bottom: -5%;
  }
  .contentText {
    padding-top: 3%;
    padding-bottom: 3%;
  }
  .homeText {
    padding-top: 5%;
  }
  .homeSection {
    height: 90%;
    margin-bottom: -30%;
  }
  .homeBack {
    height: 58%;
  }
}

@media only screen and (min-device-width: 700px) and (max-device-width: 900px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 80%;
    margin-bottom: 5%;
  }
}
@media only screen and (min-device-width: 500px) and (max-device-width: 700px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 70%;
    margin-bottom: 3%;
  }
  .mainTextHome {
    font-size: 65px;
    line-height: 100%;
  }
  .contentTextHome {
    font-size: 22px;
  }
  .whiteButton {
    padding: 12px 60px;
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 500px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 60%;
    margin-bottom: 7%;
  }
  .mainTextHome {
    font-size: 45px;
    line-height: 100%;
  }
  .contentTextHome {
    font-size: 18px;
  }
  .whiteButton {
    padding: 8px 40px;
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 200px) and (max-device-width: 300px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 55%;
    margin-bottom: 3%;
  }
  .mainTextHome {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextHome {
    font-size: 16px;
  }
  .whiteButton {
    padding: 5px 20px;
    font-size: 11px;
  }
}
@media only screen and (min-device-width: 100px) and (max-device-width: 200px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 25%;
    margin-bottom: 3%;
  }
  .mainTextHome {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextHome {
    font-size: 16px;
  }
  .whiteButton {
    padding: 5px 20px;
    font-size: 11px;
  }
}
@media only screen and (min-width: 1526px) and (max-width: 3226px) {
  .homeSection {
    height: 140%;
    margin-bottom: -20%;
  }
  .homeBack {
    display: block;
  }
  .homeBack1 {
    display: none;
  }
}
@media only screen and (min-width: 1226px) and (max-width: 1526px) {
  .homeSection {
    height: 90%;
    margin-bottom: -20%;
  }
  .homeBack {
    display: block;
  }
  .homeBack1 {
    display: none;
  }
}
@media only screen and (min-width: 1130px) and (max-width: 1226px) {
  .homeSection {
    height: 85%;
    margin-bottom: -20%;
  }
  .homeBack {
    display: block;
  }
  .homeBack1 {
    display: none;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1130px) {
  .homeBack {
    display: block;
  }
  .homeBack1 {
    display: none;
  }
  .homeSection {
    height: 70%;
    margin-bottom: -5%;
  }
  .contentText {
    padding-top: 3%;
    padding-bottom: 3%;
  }
  .homeText {
    padding-top: 5%;
  }
  .homeSection {
    height: 90%;
    margin-bottom: -30%;
  }
  .homeBack {
    height: 58%;
  }
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 80%;
    margin-bottom: 5%;
  }
}
@media only screen and (min-width: 500px) and (max-width: 700px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 70%;
    margin-bottom: 3%;
  }
  .mainTextHome {
    font-size: 65px;
    line-height: 100%;
  }
  .contentTextHome {
    font-size: 22px;
  }
  .whiteButton {
    padding: 12px 60px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 500px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 60%;
    margin-bottom: 7%;
  }
  .mainTextHome {
    font-size: 45px;
    line-height: 100%;
  }
  .contentTextHome {
    font-size: 18px;
  }
  .whiteButton {
    padding: 8px 40px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 300px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 55%;
    margin-bottom: 3%;
  }
  .mainTextHome {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextHome {
    font-size: 16px;
  }
  .whiteButton {
    padding: 5px 20px;
    font-size: 11px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 200px) {
  .homeBack {
    display: none;
  }
  .homeBack1 {
    display: block;
  }
  .homeSection {
    height: 25%;
    margin-bottom: 3%;
  }
  .mainTextHome {
    font-size: 35px;
    line-height: 100%;
  }
  .contentTextHome {
    font-size: 16px;
  }
  .whiteButton {
    padding: 5px 20px;
    font-size: 11px;
  }
}

.neighborhoods {
  z-index: 2;
  /* background-image: url("./images/soraImage.jpg"); */
  background: url(/static/media/soraImage1.eb8513ea.jpg),
    linear-gradient(rgb(0, 0, 0, 1), rgba(0, 0, 0, 1));
  /* background-position: top; */
  background-size: cover;
  height: 100%;
  margin-right: 22%;
  width: 97%;
  box-sizing: inherit;
  box-shadow: 50px 50px #f4f4ef;
}
.contentPro {
  padding-left: 20%;
  padding-right: 20%;
}
.neighborhood2 {
  z-index: 2;
}

.neighborhoodSection
{
  position: static;
  margin-left: -1.1%;
  margin-top: 20%;
  margin-bottom: 20%;
  
}
.textNeighborhood {
  margin-top: -8%;
  z-index: 3;
  font-size: 90px;
  text-transform: uppercase;
  font-weight: 800;
  font-family: "Arimo", sans-serif;
  color:#293158;
}
.contentTextNeighborhood {
  padding-top: 15px;
  padding-bottom: 25px;
  font-size: 20px;
  color: #4c5581;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
}
.neighborhoodsPoster {
  background-color: #f4f4ef;
  float: right;
  display: block;
  z-index: 1;
  width: 56%;
}
.contentButton {
  padding: 16px 90px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  background-color: transparent;
  border: 3px solid #3e4670;
  color: #3e4670;
}
.contentButton:hover {
 text-decoration: none;
  color: #3e4670;
}

.homeBack1 {
  height: 75%;
}

.contentText {
  padding-bottom: 1%;
}
.homeText {
  padding-top: 5%;
}

@media only screen and (min-width: 1926px) and (max-width: 3226px) {
  .neighborhoodsPoster {
    padding-top: 28%;
    padding-left: 39%;
    padding-right: 5%;
    margin-top: 17%;

    width: 77%;
    padding-bottom: 10%;
    margin-left: 21%;
  }

  .neighborhoods {
    height: 100%;
    height: 700px;
  }
  .neighborhoodSection1
{
  margin-bottom: 10%;
  }
  .textNeighborhood {
    margin-top: -5%;
    z-index: 3;
    font-size: 180px;
    text-transform: uppercase;
    font-weight: 800;
  }
  .contentTextNeighborhood {
    font-size: 50px;
  }
}
@media only screen and (min-width: 1700px) and (max-width: 1926px) {
  .neighborhoodsPoster {
    padding-top: 28%;
    padding-left: 39%;
    padding-right: 5%;
    margin-top: 17%;

    width: 77%;
    padding-bottom: 10%;
    margin-left: 21%;
  }

  .neighborhoods {
    height: 100%;
    padding-right: 10%;
    height: 700px;
    padding-left: 10%;
  }
  .neighborhoodSection1
{
  margin-bottom: 10%;
  }
  .textNeighborhood {
    margin-top: -6%;
    z-index: 3;
    font-size: 110px;
    text-transform: uppercase;
    font-weight: 800;
  }
  .contentTextNeighborhood {
    font-size: 20px;
  }
}@media only screen and (min-width: 1520px) and (max-width: 1700px) {
  .neighborhoodsPoster {
    padding-top: 28%;
    padding-left: 39%;
    padding-right: 5%;
    margin-top: 17%;
    width: 77%;
    padding-bottom: 10%;
    margin-left: 21%;
  }

  .neighborhoods {
    height: 100%;
    padding-right: 10%;
    padding-left: 10%;
    height: 700px;
  }
  .neighborhoodSection1
{
  margin-bottom: 10%;
  }
  .textNeighborhood {
    margin-top: -6%;
    z-index: 3;
    font-size: 110px;
    text-transform: uppercase;
    font-weight: 800;
  }
  .contentTextNeighborhood {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1380px) and (max-width: 1520px) {
  .neighborhoodsPoster {
    padding-top: 28%;
    padding-left: 39%;
    padding-right: 5%;
    margin-top: 17%;
    width: 77%;
    padding-bottom: 10%;
    margin-left: 21%;
  }

  .neighborhoods {
    height: 600px;
    padding-right: 10%;
    padding-left: 10%;
  }
  .neighborhoodSection1
{
  margin-bottom: 10%;
  }
  .textNeighborhood {
    margin-top: -6%;
    z-index: 3;
    font-size: 110px;
    text-transform: uppercase;
    font-weight: 800;
  }
  .contentTextNeighborhood {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1250px) and (max-width: 1380px) {
  .neighborhoodsPoster {
    padding-top: 17%;
    padding-left: 39%;
    padding-right: 5%;
    margin-top: 20%;
    width: 77%;
    padding-bottom: 10%;
    margin-left: 20%;
  }
  .neighborhoods {
    height: 95%;
    height: 500px;
  }
  .textNeighborhood {
    font-size: 115px;
    margin-top: -5%;
  }
  .neighborhoodSection
{

  margin-bottom: 10%;
    margin-top: 25%;
  }
}

@media only screen and (min-width: 1150px) and (max-width: 1250px) {
  .neighborhoodsPoster {
    padding-top: 19%;
    padding-left: 34%;
    padding-right: 5%;
    margin-top: 21%;
    width: 77%;
    padding-bottom: 6%;
    margin-left: 21%;
  }
  .neighborhoods {
    height: 90%;
    height: 500px;
  }
  .textNeighborhood {
    font-size: 115px;
    margin-top: -5%;
  }
  .neighborhoodSection1
{
    margin-bottom: 45%;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1150px) {
  .neighborhoodsPoster {
    margin-top: 24%;
    padding-top: 19%;
    padding-left: 35%;
    padding-right: 2%;
    width: 80%;
    margin-left: 18%;
    padding-bottom: 5%;
  }
  .neighborhoods {
    margin-top: 6%;
    height: 500px;

  }
  .textNeighborhood {
    font-size: 110px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
  margin-bottom: 10%;
  }
  .neighborhoodSection{
    margin-bottom: 10%;
  }
}
@media only screen and (min-width: 890px) and (max-width: 1000px) {
  .neighborhoodsPoster {
    margin-top: 33%;
    padding-top: 20%;
    padding-left: 35%;
    padding-right: 2%;
    width: 80%;
    margin-left: 19%;
    padding-bottom: 5%;
  }
  .neighborhoods {
    margin-top: 15%;
    height: 70%;
    height: 500px;
  }

  .neighborhoods {
    margin-top: 6%;
    height: 75%;
    height: 500px;
  }
  .textNeighborhood {
    font-size: 95px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
    margin-bottom: 52%;
    margin-top: 32%;
  }
}
@media only screen and (min-width: 820px) and (max-width: 890px) {
  .neighborhoodsPoster {
    margin-top: 20%;
    padding-top: 18%;
    padding-left: 35%;
    padding-right: 2%;
    width: 80%;
    margin-left: 17%;
    padding-bottom: 5%;
  }

  .neighborhoods {
    margin-top: 6%;
    height: 75%;
    height: 450px;
  }
  .textNeighborhood {
    font-size: 85px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
    margin-bottom: 57%;
    margin-top: 10%;
  }
}
@media only screen and (min-width: 780px) and (max-width: 820px) {
  .neighborhoodsPoster {
    margin-top: 17%;
    padding-top: 20%;
    padding-left: 35%;
    padding-right: 2%;
    width: 80%;
    margin-left: 17%;
    padding-bottom: 5%;
  }
  .neighborhoods {
    height: 58%;
    font-weight: 1200;
    height: 450px;
  }
  .neighborhoods {
    margin-top: 6%;
    height: 75%;
    height: 450px;
  }
  .textNeighborhood {
    font-size: 80px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
    margin-bottom: 60%;
    margin-top: 10%;
  }
}
@media only screen and (min-width: 700px) and (max-width: 780px) {
  .neighborhoodsPoster {
    margin-top: 15%;
    padding-top: 19%;
    padding-left: 35%;
    padding-right: 2%;
    width: 80%;
    margin-left: 17%;
    padding-bottom: 5%;
  }

  .contentTextNeighborhood {
    font-size: 18px;
  }
  .neighborhoods {
    margin-top: 6%;
    height: 75%;
    height: 400px;
  }
  .textNeighborhood {
    font-size: 75px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
    margin-bottom: 64%;
    margin-top: 10%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 700px) {
  .contentButton {
    padding: 12px 60px;
    font-size: 12px;
  }
  .neighborhoodsPoster {
    margin-top: 28%;
    padding-top: 30%;
    padding-left: 35%;
    padding-right: 2%;
    width: 80%;
    margin-left: 17%;
    padding-bottom: 5%;
  }

  .contentTextNeighborhood {
    font-size: 20px;
  }
  .neighborhoods {
    margin-top: 6%;
    height: 75%;
    height: 400px;
  }
  .textNeighborhood {
    font-size: 70px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
    margin-bottom: 78%;
    margin-top: 10%;
  }
}
@media only screen and (min-width: 500px) and (max-width: 600px) {
  .contentButton {
    padding: 12px 60px;
    font-size: 12px;
  }
  .neighborhoodsPoster {
    margin-top: 30%;
    padding-top: 43%;
    padding-left: 35%;
    padding-right: 2%;
    width: 80%;
    margin-left: 17%;
    padding-bottom: 5%;
  }

  .contentTextNeighborhood {
    font-size: 17px;
  }
  .neighborhoods {
    margin-top: 6%;
    height: 75%;
    height: 400px;
  }
  .textNeighborhood {
    font-size: 55px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
    margin-bottom: 90%;
    margin-top: 10%;
  }
}
@media only screen and (min-width: 400px) and (max-width: 500px) {
  .contentButton {
    padding: 8px 40px;
    font-size: 12px;
  }
  .neighborhoodsPoster {
    margin-top: 30%;
    padding-top: 45%;
    padding-left: 35%;
    padding-right: 2%;
    width: 80%;
    margin-left: 16%;
    padding-bottom: 5%;
  }

  .neighborhoods {
    margin-top: 6%;
    height: 60%;
    height: 300px;
  }
  .textNeighborhood {
    font-size: 40px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
    margin-bottom: 95%;
    margin-top: 10%;
  }
  .contentTextNeighborhood {
    font-size: 15px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 400px) {
  .contentButton {
    padding: 8px 40px;
    font-size: 12px;
  }
  .neighborhoodsPoster {
    margin-top: 36%;
    padding-top: 57%;
    padding-left: 35%;
    padding-right: 2%;
    width: 80%;
    margin-left: 16%;
    padding-bottom: 5%;
  }
  .neighborhoods {
    margin-top: 6%;
    height: 50%;
    height: 300px;
  }
  .textNeighborhood {
    font-size: 33px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
    margin-bottom: 110%;
    margin-top: 10%;
  }
  .contentTextNeighborhood {
    font-size: 12px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 300px) {
  .contentButton {
    padding: 5px 20px;
    font-size: 11px;
  }

  .contentTextNeighborhood {
    font-size: 16px;
  }
  .neighborhoods {
    margin-top: 6%;
    height: 40%;
    height: 200px;
  }
  .textNeighborhood {
    font-size: 20px;
    margin-top: -6%;
  }
  .neighborhoodSection1
{
    margin-bottom: 12%;
    margin-top: 10%;
  }
  .contentTextNeighborhood {
    font-size: 8px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 200px) {
  .contentButton {
    padding: 5px 20px;
    font-size: 11px;
  }
}

.FooterSection {
  background-color: #f3f3e7;
  padding-top: 3%;
}
.footerLogo {
  height: 100px;
  width: 100px;
  
}

.logoTitle {
  font-size: 60px;
  text-transform: uppercase;
  font-weight: 800;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  font-family: "Montserrat", sans-serif;
  
  color: #d15428;
}
.logoSubTitle {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 10;
  line-height: 5px;
  font-family: sans-serif;
  color: #d15428;
  font-family: "Montserrat", sans-serif;
}
.footerMenu {
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
   font-family: "Montserrat", sans-serif;
  color: #d15428;
  white-space: nowrap;
  cursor: pointer;
}
.footerMenu:hover {
  color: #f1c00c;
}
.menuHead {
  padding-right: 1%;
  padding-left: 1%;
  padding-top: 4%;
}
.footerDetail {
  padding-top: 3%;
  padding-right: 2%;
  padding-left: 2%;
}
.footerDetailHead {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  font-family: "Montserrat", sans-serif;
  color: #d15428;
  white-space: nowrap;
}
.footerDetailContent {
  font-size: 18px;
  font-weight: 350;
  font-family: sans-serif;
  font-family: "Montserrat", sans-serif;
  color: #d15428;
}
.alignDetail {
  padding-left: 20%;
  padding-right: 20%;
}
.footerDetailOfficial {
  padding-top: 2%;
  padding-right: 10%;
  padding-left: 10%;
}
.footerDetailContent2 {
  font-size: 14px;
  line-height: 17px;
  font-weight: 2;
  font-family: "Arimo", sans-serif;
  font-family: "Montserrat", sans-serif;
  color: #d15428;
}
.iconFooter {
  color: #d15428;
  font-size: 20px;
  padding: 0.5%;
}
.alignKamaik {
  text-align: left;
}

@media only screen and (min-width: 1010px) and (max-width: 1160px) {
  .footerDetailOfficial {
    padding-right: 5%;
    padding-left: 5%;
  }
}
@media only screen and (min-device-width: 900px) and (max-width: 1010px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
}
@media only screen and (min-width: 750px) and (max-width: 900px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 20px;
  }
  .footerDetailContent {
    font-size: 16px;
  }
  .footerDetailHead {
    font-size: 15px;
  }
  .iconFooter {
    font-size: 18px;
    padding: 0.3%;
  }
}
@media only screen and (min-width: 650px) and (max-width: 750px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 17px;
  }
  .footerDetailContent {
    font-size: 14px;
  }
  .footerDetailHead {
    font-size: 12px;
  }
  .iconFooter {
    font-size: 15px;
    padding: 0%;
  }
}
@media only screen and (min-width: 500px) and (max-width: 650px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 32px;
  }
  .menuHead {
    padding-bottom: 2%;
  }
  .footerDetailContent {
    font-size: 16px;
  }
  .footerDetailHead {
    font-size: 16px;
  }
  .iconFooter {
    font-size: 15px;
    padding-bottom: 4%;
  }
  .menuSubHead {
    min-width: 400px;
  }
  .footerSubDetail {
    min-width: 400px;
  }
  .footerSubDetailOfficial {
    min-width: 400px;
    padding-top: 2%;
  }
  .alignKamaik {
    text-align: center;
  }
  .footerPara {
    padding-right: 15%;
    padding-left: 15%;
  }
}
@media only screen and (min-width: 350px) and (max-width: 500px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 28px;
  }
  .menuHead {
    padding-bottom: 2%;
  }
  .footerDetailContent {
    font-size: 14px;
  }
  .footerDetailHead {
    font-size: 14px;
  }
  .iconFooter {
    font-size: 15px;
    padding-bottom: 4%;
  }
  .menuSubHead {
    min-width: 400px;
  }
  .footerSubDetail {
    min-width: 400px;
  }
  .footerSubDetailOfficial {
    min-width: 400px;
    padding-top: 2%;
  }
  .alignKamaik {
    text-align: center;
  }
  .footerPara {
    padding-right: 15%;
    padding-left: 15%;
  }
}
@media only screen and (min-width: 100px) and (max-width: 350px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 22px;
  }
  .menuHead {
    padding-bottom: 2%;
  }
  .footerDetailContent {
    font-size: 11px;
  }
  .footerDetailHead {
    font-size: 12px;
  }
  .iconFooter {
    font-size: 12px;
    padding-bottom: 4%;
  }
  .menuSubHead {
    min-width: 200px;
  }
  .footerSubDetail {
    min-width: 200px;
  }
  .footerSubDetailOfficial {
    min-width: 200px;
    padding-top: 2%;
  }
  .alignKamaik {
    text-align: center;
  }
  .footerPara {
    padding-right: 15%;
    padding-left: 15%;
  }
}
@media only screen and (min-device-width: 1010px) and (max-device-width: 1160px) {
  .footerDetailOfficial {
    padding-right: 5%;
    padding-left: 5%;
  }
}
@media only screen and (min-device-width: 900px) and (max-device-width: 1010px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
}
@media only screen and (min-device-width: 750px) and (max-device-width: 900px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 20px;
  }
  .footerDetailContent {
    font-size: 16px;
  }
  .footerDetailHead {
    font-size: 15px;
  }
  .iconFooter {
    font-size: 18px;
    padding: 0.3%;
  }
}
@media only screen and (min-device-width: 650px) and (max-device-width: 750px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 17px;
  }
  .footerDetailContent {
    font-size: 14px;
  }
  .footerDetailHead {
    font-size: 12px;
  }
  .iconFooter {
    font-size: 15px;
    padding: 0%;
  }
}
@media only screen and (min-device-width: 500px) and (max-device-width: 650px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 32px;
  }
  .menuHead {
    padding-bottom: 2%;
  }
  .footerDetailContent {
    font-size: 16px;
  }
  .footerDetailHead {
    font-size: 16px;
  }
  .iconFooter {
    font-size: 15px;
    padding-bottom: 4%;
  }
  .menuSubHead {
    min-width: 400px;
  }
  .footerSubDetail {
    min-width: 400px;
  }
  .footerSubDetailOfficial {
    min-width: 400px;
    padding-top: 2%;
  }
  .alignKamaik {
    text-align: center;
  }
  .footerPara {
    padding-right: 15%;
    padding-left: 15%;
  }
}
@media only screen and (min-device-width: 350px) and (max-device-width: 500px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 28px;
  }
  .menuHead {
    padding-bottom: 2%;
  }
  .footerDetailContent {
    font-size: 14px;
  }
  .footerDetailHead {
    font-size: 14px;
  }
  .iconFooter {
    font-size: 15px;
    padding-bottom: 4%;
  }
  .menuSubHead {
    min-width: 400px;
  }
  .footerSubDetail {
    min-width: 400px;
  }
  .footerSubDetailOfficial {
    min-width: 400px;
    padding-top: 2%;
  }
  .alignKamaik {
    text-align: center;
  }
  .footerPara {
    padding-right: 15%;
    padding-left: 15%;
  }
}
@media only screen and (min-device-width: 200px) and (max-device-width: 350px) {
  .footerDetailOfficial {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerDetail {
    padding-right: 0%;
    padding-left: 0%;
  }
  .footerMenu {
    font-size: 22px;
  }
  .menuHead {
    padding-bottom: 2%;
  }
  .footerDetailContent {
    font-size: 11px;
  }
  .footerDetailHead {
    font-size: 12px;
  }
  .iconFooter {
    font-size: 12px;
    padding-bottom: 4%;
  }
  .menuSubHead {
    min-width: 200px;
  }
  .footerSubDetail {
    min-width: 200px;
  }
  .footerSubDetailOfficial {
    min-width: 200px;
    padding-top: 2%;
  }
  .alignKamaik {
    text-align: center;
  }
  .footerPara {
    padding-right: 15%;
    padding-left: 15%;
  }
}

.menu {
  background-color: #222967;
}
.hide {
  display: none;
}
.bgPrimary {
  background-color: #222967;
}
.menuSection {
  height: 100%;
  background-color: #d6d6d5;
}

.menuBack {
  background-image: url(/static/media/bird2.590438c1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  padding-left: 3%;
  padding-top: 8%;
  padding-bottom: 15%;
  width: 72.7%;
}
.menuBackImage1 {
  background-color: black;
  background-image: url(/static/media/bird2.590438c1.png);
  height: 640px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 9%;

  padding-bottom: 10%;
}
.menuText {
  /* font-family: "Bourton W00", sans-serif; */
  font-family: "Roboto Condensed", sans-serif;
  color: #fff;
  font-size: 75px;
  text-transform: uppercase;
  font-weight: 900;
  padding-top: 5%;
  line-height: 80%;
  text-decoration: none !important;
}
.menuText:hover {
  color: #f1c00c;
}
.menuDetails {
  width: 26.3%;
  padding-left: 5%;
  padding-top: 14%;
  background-color: #d6d6d5;
}
.menuSection2 {
  background-color: #d6d6d5;
}
.menuDetail {
  padding-bottom: 14%;
  padding-right: 1%;
  padding-left: 1%;
}
.menuDetails2 {
  background-color: #d6d6d5;
  padding-top: 5%;
}
.menuDetails3 {
  padding-bottom: 15%;
}
.menuDetailHeaderText {
  font-family: "Roboto Condensed", sans-serif;
  color: #d15428;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 900;
  padding-bottom: 10px;
}
.menuDetailText {
  color: #d15428;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.round {
  align-items: center;
  color: #fff;
  background-color: #d15428;
  width: 37px;
  padding: 12px;
  border-radius: 20px;
}
@media only screen and (min-width: 1470px) and (max-width: 5160px) {
  .menuSection {
    display: block;
  }
  .menuSection2 {
    display: none;
  }
}
@media only screen and (min-device-width: 850px) and (max-device-width: 1470px) {
  /* .menuBack{
        width:80%;
    }
    .menuDetails{
        width:20%;
    } */
  .menuSection {
    display: block;
  }
  .menuSection2 {
    display: none;
  }
}
@media only screen and (min-device-width: 650px) and (max-device-width: 850px) {
  .menuSection {
    display: none;
  }
  .menuSection2 {
    display: block;
  }
}
@media only screen and (min-device-width: 570px) and (max-device-width: 650px) {
  .menuSection {
    display: none;
  }
  .menuSection2 {
    display: block;
  }
  .menuText {
    font-size: 60px;
  }
  .menuBackImage1 {
    height: 550px;
  }
}
@media only screen and (min-device-width: 510px) and (max-device-width: 570px) {
  .menuSection {
    display: none;
  }
  .menuSection2 {
    display: block;
  }
  .menuText {
    font-size: 40px;
  }
  .menuBackImage1 {
    height: 500px;
  }
  .menuSub {
    min-width: 500px;
  }
  .alignMenu2 {
    padding-top: 10%;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 510px) {
  .menuSection {
    display: none;
  }
  .menuSection2 {
    display: block;
  }
  .menuText {
    font-size: 30px;
  }
  .menuBackImage1 {
    height: 400px;
  }
  .menuSub {
    min-width: 300px;
  }
  .alignMenu2 {
    padding-top: 10%;
  }
  .menuDetailHeaderText {
    font-size: 23px;
    padding-bottom: 3px;
  }
  .menuDetailText {
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 310px) and (max-device-width: 372px) {
  .menuSection {
    display: none;
  }
  .menuSection2 {
    display: block;
  }
  .menuText {
    font-size: 30px;
  }
  .menuBackImage1 {
    height: 350px;
  }
  .menuSub {
    min-width: 300px;
  }
  .alignMenu2 {
    padding-top: 15%;
  }
  .menuDetailHeaderText {
    font-size: 20px;
    padding-bottom: 3px;
  }
  .menuDetailText {
    font-size: 10px;
  }
}

.logo {
  height: 120px;
  width: 120px;
  position: absolute;
}
.logo1 {
  height: 90px;
  width: 80px;
  position: absolute;
}
.logoPart {
  padding-left: 3.5%;
  cursor: pointer;
}
.menuButton {
  border: none;
  color: white;
  padding: 15px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 17px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  background-color: #d15428;
}

.menuButton:hover {
  background-color: #e47b55;
}
.bgPrimary {
  background-color: #d15428;
}
.textPrimary {
  color: #d15428;
}
.menuPlus {
  -webkit-text-stroke: 2px #d15428;
}
.availableButton {
  background: none !important;
  font-weight: 800;
  border: none;
  font-size: 11px;
  bottom: 2px;
  font-family: arial, sans-serif;
  text-transform: uppercase;
  cursor: pointer;
  font-family: "Arimo", sans-serif;
  font-family: "Libre Franklin", sans-serif;
  padding-right: 7%;
}
.availableButton:hover {
  text-decoration: underline;
  color:#e47b55;
  text-underline-offset: 4px;
}
.navRight {
  padding-top: 1.5rem;
  padding-right: 4%;
  position: fixed;
  width: 100%;
  float: right;
}
.navLeft {
  position: fixed;
  width: 100%;

  float: left;
}
.header {
  position: absolute;
  width: 100%;
  z-index: 10;
}
.turnClose {
  -webkit-animation: turn 0.3s linear 1;
  transform: rotate(45deg);
}
@keyframes turn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
.turnMenu {
  -webkit-animation: turn1 0.3s linear 1 reverse;
  transform: rotate(0deg);
}

@keyframes turn1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
.menuClose {
  animation: changeover 3s linear;
}
@keyframes changeover {
  0% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(10px);
  }
}
.transformAvailability {
  transform: translate(24px);
}

@media only screen and (min-device-width: 200px) and (max-device-width: 900px) {
  .logo {
    height: 65px;
    width: 50px;
  }
  .logo1 {
    height: 65px;
    width: 50px;
  }
  
}
@media only screen and (min-device-width: 450px) and (max-device-width: 550px) {
  .menuButton {
    font-size: 17px;
    padding: 14px 22px;
  
  }
}
@media only screen and (min-device-width: 350px) and (max-device-width: 450px) {
  .menuButton {
    font-size: 16px;
    padding: 11px 19px;
  
  }
}
@media only screen and (min-device-width: 100px) and (max-device-width: 350px) {
  .menuButton {
    font-size: 14px;
    padding: 8px 15px;
  
  }
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  top: 200%;
  left: 50%;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.fontStyle{
  font-family: "Montserrat", sans-serif;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #f76c39;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.bgPrimary{
  background-color: #da6940;
  color: #fff;
}
.textAvailableUnit {
  margin-left: 4%;
  font-size: 75px;
  text-transform: uppercase;
  font-weight: 1000;
  color: #d15428;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 0%;
  margin-top: 10px;
}
.textAvailableUnitModal {
  margin-left: 4%;
  font-size: 75px;
  text-transform: uppercase;
  font-weight: 1000;
  color: #d15428;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 0%;
  margin-top: 10px;
}
.detailsHead {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 600;
  color: #d15428;
  margin-top: 15px;
  margin-bottom: 10px;
}
.detailsHeadModal1 {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 600;
  color: #2e2e2e;
  margin-top: 15px;
  margin-bottom: 10px;
}
.detailsHeadModal2 {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #d15428;
  margin-top: 10px;
  margin-bottom: 5px;
}
.detailContentAvailable {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 300;
}
.shadow1 {
  box-shadow: 0px 0 25px rgba(0, 0, 0, 0.4);
}
.modal {
  /* width:1800px; */
}
.modal-content {
}
.img-wrap {
  width: 100%;
}
.imageAvailable {
  max-width: 100%;
  max-height: 100%;
  display: block;
  min-width: 200px;
  width: 100%;
}
.detailAvailable {
  overflow-y: scroll;
  max-height: 30vmax;
  width: 65%;
}
.detailAvailable::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.detailAvailable::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.detailAvailable::-webkit-scrollbar-thumb {
  background: #f1845c;
}

/* Handle on hover */
.detailAvailable::-webkit-scrollbar-thumb:hover {
  background: #e4b86b;
}
.modal {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.bodyAvailable {
}
#exampleModal {
  /* width:1500px; */
}
.availableTable {
  border-top: 1px solid #cfae75;
  border-bottom: 1px solid #cfae75;
  padding-top: 3%;
 
}
.availableTableRow {
  padding-bottom: 4%;
}
.availableUnitTableHead {
  margin-bottom: 1%;
}
.availableTableContent {
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
.availableTableHead {
  margin-left: 4%;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #d15428;
  font-family: "Montserrat", sans-serif;
}
.availableTableHead1 {
  font-size: 15px;
  text-transform: uppercase;
  color: #d15428;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
.availableTableHead2 {
  font-size: 13px;
  text-transform: uppercase;
  color: #383838;
  font-family: sans-serif;
  text-decoration: underline;
  font-family: "Montserrat", sans-serif;
}
.availableUnitSection {
  padding-top: 7%;
}
.availableUnitSection2 {
  padding-top: 15%;
}
.availableUnitTable {
  padding-left: 4%;
  padding-right: 4%;
}
.availableUnitButton {
  padding: 12px 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  border: 3px solid #e4b86b;
  color: #e4b86b;
  margin-top: -100%;
  white-space: nowrap;
}
.primaryAvailableUnitButton {
  padding: 12px 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  border: 3px solid #d15428;
  color: #d15428;
  margin-top: -100%;
}
.primaryAvailableUnitButton:hover {
  background-color: transparent;
  border: 3px solid #d15428;
  color: #d15428;
  text-decoration: none;
  background: rgba(226, 170, 49, 0.2);
}
.availableUnitButton:hover {
  color: #e4b86b;
  background-color: transparent;
  text-decoration: none;
  background: rgba(226, 224, 205, 0.2);
}

.availableUnitButtonModal {
  padding: 12px 20px;
  cursor: pointer;

  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  border: 3px solid #e4b86b;
  color: #e4b86b;
  margin-top: -100%;
  white-space: nowrap;
}
.primaryAvailableUnitButtonModal {
  padding: 12px 20px;
  cursor: pointer;
  margin-left: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 0%;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  border: 3px solid #d15428;
  color: #d15428;
  margin-top: -100%;
}
.primaryAvailableUnitButtonModal:hover {
  background-color: transparent;
  border: 3px solid #d15428;
  color: #d15428;
  text-decoration: none;
  background: rgba(226, 170, 49, 0.2);
}
.availableUnitButtonModal:hover {
  color: #e4b86b;
  background-color: transparent;
  text-decoration: none;
  background: rgba(226, 224, 205, 0.2);
}
hr.line {
  color: #e4b86b;
  height: 9px;
}
.brownYellow {
  color: #e4b86b;
}
.soraMap{
  min-width:600px;
  min-height:350px;
  border:0;
}
.contactAvailable{
  padding-right:3vmax;
  padding-left:3vmax;
}
@media only screen and (min-height: 1600px) and (max-height: 5000px) {
  .detailAvailable {
    overflow-y: scroll;
    max-height: 55vmax;
    width: 65%;
  }
}
@media only screen and (min-height: 1200px) and (max-height: 1600px) {
  .detailAvailable {
    overflow-y: scroll;
    max-height: 45vmax;
    width: 65%;
  }
}
@media only screen and (min-height: 800px) and (max-height: 1200px) {
  .detailAvailable {
    overflow-y: scroll;
    max-height: 35vmax;
    width: 65%;
  }
}
@media only screen and (min-height: 650px) and (max-height: 800px) {
  .detailAvailable {
    overflow-y: scroll;
    max-height: 31vmax;
    width: 65%;
  }
}
@media only screen and (min-height: 430px) and (max-height: 650px) {
  .detailAvailable {
    overflow-y: scroll;
    max-height: 30vmax;
    width: 65%;
  }
}
@media only screen and (min-height: 300px) and (max-height: 430px) {
  .detailAvailable {
    overflow-y: scroll;
    max-height: 28vmax;
    width: 65%;
  }
}
@media only screen and (min-height: 200px) and (max-height: 300px) {
  .detailAvailable {
    overflow-y: scroll;
    max-height: 25vmax;
    width: 65%;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 5000px) {
  .availableUnitSection2 {
    display: none;
  }
  .availableUnitSection {
    display: block;
  }
  .ModalAvailable {
    height: 100px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 70px;
    margin-top: -20px;
  }
  .imageAvailable {
    min-width: 500px;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1600px) {
  .availableUnitSection2 {
    display: none;
  }
  .availableUnitSection {
    display: block;
  }
  .textAvailableUnit {
    font-size: 55px;
  }
  .ModalAvailable {
    height: 80px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 55px;
    margin-top: -20px;
  }
  .imageAvailable {
    /* min-width:500px; */
  }
  .soraMap{
    min-width:600px;
    height:350px;
    border:0;
  }
}
@media only screen and (min-width: 1040px) and (max-width: 1300px) {
  .availableUnitSection2 {
    display: none;
  }
  .availableUnitSection {
    display: block;
  }
  .availableUnitButton {
    font-size: 10px;
    padding: 10px 15px;
  }
  .primaryAvailableUnitButton {
    font-size: 10px;
    padding: 10px 15px;
  }
  .availableUnitButtonModal {
    font-size: 10px;
    padding: 10px 15px;
  }
  .detailsFooter {
    max-width: 500px;
  }
  .primaryAvailableUnitButtonModal {
    font-size: 10px;
    margin-left: 10px;
    padding: 10px 15px;
  }
  .textAvailableUnit {
    font-size: 55px;
  }
  .ModalAvailable {
    height: 75px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 60px;
    margin-top: -25px;
  }
  .soraMap{
    width:550px;

    border:0;
  }
}

@media only screen and (min-width: 980px) and (max-width: 1040px) {
  .availableUnitSection2 {
    display: none;
  }
  .availableUnitSection {
    display: block;
  }
  .textAvailableUnit {
    font-size: 55px;
  }
  .availableUnitTable {
    padding-left: 0%;
    padding-right: 0%;
  }
  .availableUnitSection {
    padding-top: 10%;
  }
  .availableUnitButton {
    font-size: 8px;
    padding: 8px 10px;
  }
  .detailsFooter {
  }
  .primaryAvailableUnitButton {
    font-size: 8px;
    padding: 8px 10px;
  }
  .availableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
    margin-left: -50px;
  }
  .primaryAvailableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
    margin-left: 5px;
  }
  .ModalAvailable {
    height: 75px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 60px;
    margin-top: -25px;
  }
  .ModalAvailable {
    height: 70px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 45px;
    margin-top: -15px;
  }
  .detailsHead {
    font-size: 15px;
  }
  .detailsHeadModal1 {
    font-size: 22px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .detailsHeadModal2 {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .detailContentAvailable {
    font-size: 18px;
  }
  .soraMap{
    min-width:520px;
    border:0;
  }
}
@media only screen and (min-width: 800px) and (max-width: 980px) {
  .availableUnitSection2 {
    display: none;
  }
  .availableUnitSection {
    display: block;
  }
  .textAvailableUnit {
    font-size: 55px;
  }
  .availableUnitTable {
    padding-left: 0%;
    padding-right: 0%;
  }
  .availableUnitSection {
    padding-top: 10%;
  }
  .availableUnitButton {
    font-size: 8px;
    padding: 8px 10px;
  }
  .detailsFooter {
    max-width: 250px;
  }
  .primaryAvailableUnitButton {
    font-size: 8px;
    padding: 8px 10px;
  }
  .availableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
    margin-left: -50px;
  }
  .primaryAvailableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
    margin-left: 5px;
  }
  .ModalAvailable {
    height: 75px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 60px;
    margin-top: -25px;
  }
  .ModalAvailable {
    height: 70px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 45px;
    margin-top: -15px;
  }
  .detailsHead {
    font-size: 15px;
  }
  .detailsHeadModal1 {
    font-size: 21px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .detailsHeadModal2 {
    font-size: 17px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .detailContentAvailable {
    font-size: 17px;
  }
  .soraMap{
    min-width:420px;
    border:0;
  }
}
@media only screen and (min-width: 650px) and (max-width: 800px) {
  .availableUnitSection2 {
    display: block;
  }
  .availableUnitSection {
    display: none;
  }
  .textAvailableUnit {
    font-size: 45px;
  }
  .availableUnitTable {
    padding-left: 0%;
    padding-right: 0%;
  }
  .availableTableContent {
    line-height: 10px;
  }
  .availableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .primaryAvailableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .detailsFooter {
    min-width: 450px;
  }
  .availableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
  }
  .primaryAvailableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
    margin-left: 20px;
  }
  .ModalAvailable {
    height: 70px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 45px;
    margin-top: -15px;
  }
  .detailsHeadModal1 {
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .detailsHeadModal2 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .detailContentAvailable {
    font-size: 16px;
  }
  .soraMap{
    min-width:650px;
    min-height:300px;
    border:0;
  }
  
}

@media only screen and (min-width: 500px) and (max-width: 650px) {
  .availableUnitSection2 {
    display: block;
  }
  .availableUnitSection {
    display: none;
  }
  .textAvailableUnit {
    font-size: 45px;
  }
  .availableUnitTable {
    padding-left: 0%;
    padding-right: 0%;
  }
  .availableTableContent {
    line-height: 10px;
  }
  .availableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .primaryAvailableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .detailsFooter {
    min-width: 450px;
  }
  .availableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
  }
  .primaryAvailableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
    margin-left: 10px;
  }
  .ModalAvailable {
    height: 70px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 45px;
    margin-top: -15px;
  }
  .detailsHeadModal1 {
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .detailsHeadModal2 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .detailContentAvailable {
    font-size: 16px;
  }
  .soraMap{
    min-width:500px;
    min-height:300px;
    border:0;
  }
  
}
@media only screen and (min-width: 400px) and (max-width: 500px) {
  .availableUnitSection2 {
    padding-top: 20%;
  }
  .availableUnitSection2 {
    display: block;
  }
  .availableUnitSection {
    display: none;
  }
  .textAvailableUnit {
    font-size: 30px;
  }
  .availableUnitTable {
    padding-left: 0%;
    padding-right: 0%;
  }
  .availableTableContent {
    line-height: 10px;
  }
  .availableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .primaryAvailableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .detailsFooter {
    min-width: 370px;
  }
  .availableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
  }
  .primaryAvailableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
    margin-left: 10px;
  }
  .ModalAvailable {
    height: 70px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 40px;
    margin-top: -10px;
  }
  .detailsHead {
    font-size: 18px;
  }
  .detailAvailable {
   
    min-width: 400px;
    width: 100%;
  }
  .detailContentAvailable {
    font-size: 15px;
  }
  p {
    font-size: 12px;
  }
  .imageAvailable {
    min-width: 200px;
  }

  .detailsHeadModal1 {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .detailsHeadModal2 {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .detailContentAvailable {
    font-size: 14px;
  }
  .soraMap{
    min-width:400px;
    min-height:250px;
    border:0;
  }
}
@media only screen and (min-width: 300px) and (max-width: 400px) {
  .availableUnitSection2 {
    padding-top: 30%;
  }
  .availableUnitSection2 {
    display: block;
  }
  .availableUnitSection {
    display: none;
  }
  .textAvailableUnit {
    font-size: 30px;
  }
  .availableUnitTable {
    padding-left: 0%;
    padding-right: 0%;
  }
  .availableTableContent {
    line-height: 10px;
  }
  .availableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .primaryAvailableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .detailsFooter {
    min-width: 290px;
  }
  .detailsHead {
    font-size: 17px;
  }
  .availableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
  }
  .primaryAvailableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
    margin-left: 10px;
  }
  .ModalAvailable {
    height: 50px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 25px;
    margin-top: -5px;
  }
  .detailAvailable {

    min-width: 300px;
    width: 100%;
  }
  .detailContentAvailable {
    font-size: 13px;
  }
  p {
    font-size: 13px;
  }
  .imageAvailable {
    min-width: 200px;
  }

  .detailsHeadModal1 {
    font-size: 17px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .detailsHeadModal2 {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .detailContentAvailable {
    font-size: 13px;
  }
  .soraMap{
    min-width:300px;
    min-height:250px;
    border:0;
  }
}
@media only screen and (min-width: 200px) and (max-width: 300px) {
  .availableUnitSection2 {
    padding-top: 35%;
  }
  .availableUnitSection2 {
    display: block;
  }
  .availableUnitSection {
    display: none;
  }
  .textAvailableUnit {
    font-size: 25px;
  }
  .availableUnitTable {
    padding-left: 0%;
    padding-right: 0%;
  }
  .availableTableContent {
    line-height: 10px;
  }
  .availableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .primaryAvailableUnitButton {
    margin-top: 0.5%;
    display: block;
  }
  .ModalAvailable {
    height: 50px;
    border-bottom: 1px solid #e09b81;
  }
  .textAvailableUnitModal {
    font-size: 25px;
    margin-top: -5px;
  }
  .detailsFooter {
    min-width: 210px;
  }
  .detailsHead {
    font-size: 17px;
  }
  .availableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
  }
  .primaryAvailableUnitButtonModal {
    font-size: 8px;
    padding: 8px 10px;
    margin-left: 10px;
  }
  .detailAvailable {
   
    min-width: 200px;
    width: 100%;
  }
  .detailContentAvailable {
    font-size: 12px;
  }
  p {
    font-size: 12px;
  }
  .imageAvailable {
    min-width: 200px;
  }

  .detailsHeadModal1 {
    font-size: 17px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .detailsHeadModal2 {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .detailContentAvailable {
    font-size: 13px;
  }
  .soraMap{
    min-width:300px;
    min-height:200px;
    border:0;
  }
}

.InterestedSection {
  padding: 5%;
  margin-top: 5%;
  align-items: center;
}
.Box {
  border: 10px solid #d15428;
  padding-top: 7%;
  padding-bottom: 7%;
}
.interestHeading {
  font-family: "Roboto Condensed", sans-serif;
  color: #d15428;
  font-size: 105px;
  text-transform: uppercase;
  font-weight: 1000;
  padding-bottom: 2%;
  line-height: 100%;
  
}
.interestContent {
  font-family: sans-serif;
  color: #000;
  font-size: 25px;
  line-height: 100%;
  padding-bottom: 2%;
  font-family: "Montserrat", sans-serif;
  
}
@media only screen and (min-device-width: 2020px) and (max-device-width: 3250px) {
  .interestHeading {
    font-size: 175px;
  }
  .interestContent {
    font-size: 55px;
  }
}
@media only screen and (min-device-width: 1000px) and (max-device-width: 2020px) {
  .interestHeading {
    font-size: 110px;
  }
  .interestContent {
    font-size: 45px;
  }
}
@media only screen and (min-device-width: 800px) and (max-device-width: 1000px) {
  .interestHeading {
    font-size: 80px;
  }
  .interestContent {
    font-size: 35px;
  }
}
@media only screen and (min-device-width: 600px) and (max-device-width: 800px) {
  .interestHeading {
    font-size: 70px;
  }
  .interestContent {
    font-size: 30px;
  }
  .Box {
    padding-bottom: 7%;
  }
}
@media only screen and (min-device-width: 400px) and (max-device-width: 600px) {
  .interestHeading {
    font-size: 50px;
  }
  .interestContent {
    font-size: 25px;
  }
  .Box {
    padding-bottom: 7%;
  }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 400px) {
  .interestHeading {
    font-size: 40px;
  }
  .interestContent {
    font-size: 20px;
  }
  .Box {
    padding-bottom: 7%;
  }
}
@media only screen and (min-device-width: 200px) and (max-device-width: 300px) {
  .interestHeading {
    font-size: 30px;
  }
  .interestContent {
    font-size: 15px;
  }
  .Box {
    padding-bottom: 7%;
  }
}

